import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { filter, map } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';

import { AwareSecurityPermissionProvider } from '@appbolaget/aware-security';

import { AuthState, UnitState } from '@shared/state';
import { environment } from '@env';

const jwtHelper = new JwtHelperService();

@Injectable({ providedIn: 'root' })
export class PermissionProvider implements AwareSecurityPermissionProvider {
    constructor(private store: Store) {}

    getPermissions() {
        return this.store.select(AuthState.token).pipe(
            filter((token) => !!token),
            map((token) => {
                const decoded: any = jwtHelper.decodeToken(token);
                const { permissions, roles } = decoded.payload;

                return { permissions, roles };
            }),
        );
    }

    getUnitId() {
        return this.store.select(UnitState.currentUnit).pipe(map((unit) => (unit ? this.getUnitsHierarchy() : [environment.api.baseUnit])));
    }

    private getUnitsHierarchy() {
        const hierarchy = this.store.selectSnapshot(UnitState.hierarchy);

        return hierarchy.map((u) => u.uuid);
    }
}
