@if (client) {
  <div class="w-[calc(100%-40px)] flex flex-row gap-3 ml-5 pb-3 border-gray-500 border-solid border-b border-opacity-30">
    @if (client.image) {
      <img [src]="client.image | imageByUuid: 100" class="object-cover w-24 h-24 rounded-full" />
    } @else {
      <!-- <div class="w-24 h-24 rounded-full flex justify-center items-center text-3xl border-[#828282] border-2 border-dashed">
      {{ client.initials }}
    </div> -->
    <div class="w-24 h-24 rounded-full flex justify-center items-center text-3xl text-primary border-primary border border-solid">
      {{ client.initials }}
    </div>
  }
  <div class="flex flex-col justify-center">
    @if (client.name) {
      <h2 class="text-lg font-semibold">{{ client.name }}</h2>
    }
    @if (client.email) {
      <p class="text-xs text-[#828282]">{{ client.email }}</p>
    }
    @if (client.phone) {
      <p class="text-xs text-[#828282]">{{ client.phone | parsePhoneNumber }}</p>
    }
  </div>
</div>
}

