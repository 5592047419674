import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

import { takeWhile, tap } from 'rxjs/operators';

import { Client } from '@appbolaget/aware-model';

import { AuthState } from '@shared/state';

@Component({
    selector: 'app-client-profile-header',
    templateUrl: 'client-profile-header.component.html',
})
export class ClientProfileHeaderComponent implements OnInit, OnDestroy {
    @Input() client: Client;

    ALIVE = true;

    constructor(private store: Store) {}

    ngOnInit() {
        if (!this.client) {
            this.store
                .select(AuthState.client)
                .pipe(
                    takeWhile(() => this.ALIVE),
                    tap((client) => {
                        this.client = client;
                    }),
                )
                .subscribe();
        }
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }
}
