import { Store } from '@ngxs/store';

import { Observable, of } from 'rxjs';

import { AuthState, GetMyUnits } from '@shared/state';

export function getMyUnits(store: Store): Observable<any> {
    const client = store.selectSnapshot(AuthState.client);

    if (client) {
        return store.dispatch(new GetMyUnits());
    }

    return of(null);
}
