export class InitializeFcm {
    static type = '[Aware Push] Initialize FCM';
}

export class SubscribeToFcmTopics {
    static type = '[Aware Push] Subscribe to topics';

    constructor(public topics: string | string[]) {}
}

export class UnsubscribeFromFcmTopics {
    static type = '[Aware Push] Unsubscribe from topics';

    constructor(public topics: string | string[]) {}
}

export class RefreshFcmTopics {
    static type = '[Aware Push] Refresh fcm topics';
}

export class SyncTokenWithAware {
    static type = '[Aware Push] Sync token with aware';
}

export class DeleteTokenFromAware {
    static type = '[Aware Push] Delete token from aware';
}
