<ion-header>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="close()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content>
    @if (!LOADING && client$ | async; as client) {
        <app-client-profile-header [client]="client"></app-client-profile-header>
        <div class="p-5 flex flex-row gap-2 w-full">
            @if (client.phone) {
                <app-icon-card icon="call-outline" label="COMPONENTS.PROFILE.CALL" size="small" (click)="call()"></app-icon-card>
            }

            @if (client.email) {
                <app-icon-card icon="mail-outline" label="COMPONENTS.PROFILE.EMAIL" size="small" (click)="mail()"></app-icon-card>
            }
        </div>
    }

    @if (LOADING) {
        <div
            class="py-5 flex gap-2 border-gray-500 border-solid border-b border-opacity-30 last:border-0 outline-none items-center justify-center text-center text-[#828282] h-full"
        >
            <ion-icon name="sync-outline" class="animate-spin"></ion-icon>
            {{ 'COMMON.LOADING' | translate }}
        </div>
    }
</ion-content>
