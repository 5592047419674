import { Unit } from '@appbolaget/aware-model';

export class SetCurrentUnit {
    static type = '[Unit] Set current unit';

    constructor(public unit: Unit, public refreshNavigations = true) {}
}

export class RefreshCurrentUnit {
    static type = '[Unit] Refresh current unit';

    constructor(public refreshNavigations = true) {}
}

export class GetTabsAndSidenav {
    static type = '[Unit] Get tabs and sidenav';
}

export class GetUnitByUuidAndSetCurrentUnit {
    static type = '[Unit] Get unit by uuid and set current unit';

    constructor(public unitUuid: string) {}
}

export class GetMyUnits {
    static type = '[Unit] Get my units';
}

export class GetOrganisation {
    static type = '[Unit] Get organisation';
}
