<ion-input
    fill="solid"
    mode="md"
    labelPlacement="floating"
    [label]="label | translate"
    [placeholder]="placeholder | translate"
    [id]="inputID"
    [type]="type"
    [formControl]="inputControl"
/>
