<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'COMPONENTS.ICON_PICKER.CHOOSE_ICON' | translate }}</ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar mode="ios">
    <ion-segment value="0" (ionChange)="segmentChanged($event)">
      <ion-segment-button value="0">
        <ion-label>{{ 'COMPONENTS.ICON_PICKER.OUTLINE' | translate }}</ion-label>
      </ion-segment-button>

      <ion-segment-button value="1">
        <ion-label>{{ 'COMPONENTS.ICON_PICKER.FILLED' | translate }}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content>
  <swiper-container #swiper [allowTouchMove]="false">
    <swiper-slide>
      <div class="flex flex-row justify-center py-2">
        <div class="flex flex-row flex-wrap gap-2 flex-shrink justify-center">
          @for (icon of outline; track icon) {
            <app-icon [button]="true" [icon]="icon" size="large" (click)="selectIcon(icon)"></app-icon>
          }
        </div>
      </div>
    </swiper-slide>

    <swiper-slide>
      <div class="flex flex-row justify-center py-2">
        <div class="flex flex-row flex-wrap gap-2 flex-shrink justify-center">
          @for (icon of filled; track icon) {
            <app-icon [button]="true" [icon]="icon" size="large" (click)="selectIcon(icon)"></app-icon>
          }
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
</ion-content>
