import { Actions, Store } from '@ngxs/store';
import { NavController } from '@ionic/angular';

import { firstValueFrom, forkJoin, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

import { AwareHttpService } from '@appbolaget/aware-http';

import { AppState } from '@shared/state';
import { checkLogoutParam, handleRefresh, refreshCurrentUnit, getMyUnits, getOrganisation, routeClient, getMyInvites } from './';

export function appInit(store: Store, actions$: Actions, navCtrl: NavController, api: AwareHttpService) {
    return async () => {
        const initted$ = new Subject<void>();

        return await firstValueFrom(
            store.select(AppState.stateInitialized).pipe(
                takeUntil(initted$),
                filter((stateInitialized) => stateInitialized),
                switchMap(() => checkLogoutParam(store)),
                switchMap(() => handleRefresh(store, actions$, initted$)),
                switchMap(() => forkJoin([refreshCurrentUnit(store), getOrganisation(store), getMyUnits(store), getMyInvites(store)])),
                switchMap(() => routeClient(store, api, navCtrl, initted$)),
            ),
        );
    };
}
