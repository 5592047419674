import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';

import { Observable } from 'rxjs';

import { Client } from '@appbolaget/aware-model';

import { AuthState } from '@shared/state';

@Component({
    selector: 'app-client-card',
    templateUrl: 'client-card.component.html',
    styleUrls: ['client-card.component.scss'],
})
export class ClientCardComponent implements OnInit, OnDestroy {
    @Select(AuthState.client) client$: Observable<Client>;

    @Input() client: Client;
    @Input() label: string;
    @Input() selected = false;
    @Input() selfAware = true;
    @Input() button = false;

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {}
}
