import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Select, Store } from '@ngxs/store';
import { AppConfig } from '@shared/interfaces';

import { LanguageState, UnitState } from '@shared/state';
import { Observable } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

@Component({
    selector: 'app-language-select',
    templateUrl: 'language-select.component.html',
})
export class LanguageSelectComponent implements OnInit, OnDestroy {
    @Select(UnitState.appConfig) appConfig$: Observable<AppConfig>;
    @Input() label: string = 'COMMON.LANGUAGE';
    @Input() cancelText: string = 'COMMON.CANCEL';
    @Input() okText: string = 'COMMON.SELECT';
    @Output() languageChanged = new EventEmitter<string>();

    languageControl: UntypedFormControl;

    ALIVE = true;

    constructor(private store: Store) {}

    ngOnInit() {
        const globalLanguage = this.store.selectSnapshot(LanguageState.language);

        this.languageControl = new UntypedFormControl(globalLanguage);

        this.languageControl.valueChanges
            .pipe(
                takeWhile(() => this.ALIVE),
                tap((value) => this.languageChanged.emit(value)),
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.ALIVE = false;
    }
}
