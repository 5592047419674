@if (unit) {
  <div class="bg-light rounded-md flex flex-row gap-2 p-2 selected-border-color border-solid"
    [ngClass]="selected ? 'border-2 p-[6px]' : ''"
    [ngClass]="button ? 'hover:cursor-pointer hover:border-2 hover:p-[6px]' : ''">
    <div class="bg-white flex justify-center items-center rounded-full w-12 h-12 relative flex-shrink-0" [ngClass]="{
        'p-[6px]': unit.image
      }">
      <img class="rounded-full w-full h-full object-contain"
        [src]="unit.image ? (unit.image | imageByUuid : 150) : 'assets/img/purple_icon.png'" />
    </div>
    <div class="flex flex-col justify-center h-12 overflow-hidden flex-1">
      <p class="ellipsis">{{ label ?? unit.title | translate }}</p>
      <p class="text-xs ellipsis w-full">{{ description ?? unit.content | stripHTML | translate }}</p>
    </div>
    @if (showNavigationArrow) {
      <div class="flex h-12 w-6 justify-center items-center">
        <ion-icon name="chevron-forward-outline" class="text-2xl"></ion-icon>
      </div>
    }
  </div>
}