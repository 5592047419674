export const environment = {
    production: false,
    api: {
        source: 'se.appbolaget.grupper',
        url: 'https://api-universal.appbolaget.se',
        baseUnit: '9a313638-c8c8-4e47-b594-5b880c9e508a',
    },
    app: {
        appId: 'se.appbolaget.minagrupper',
        version: '1.0.0',
    },
};
