import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { State, StateContext, Action, Selector, NgxsOnInit } from '@ngxs/store';

import { Device } from '@capacitor/device';

import { StateKey } from '@shared/enums';
import { SetLanguage } from './language.actions';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { AwareHttpService } from '@appbolaget/aware-http';

export interface LanguageStateModel {
    language: string;
}

const defaults = {
    language: null,
};

@State<LanguageStateModel>({
    name: StateKey.Language,
    defaults,
})
@Injectable({ providedIn: 'root' })
export class LanguageState implements NgxsOnInit {
    constructor(
        private translateService: TranslateService,
        private dateFnsConfigService: DateFnsConfigurationService,
        private api: AwareHttpService,
    ) {}

    @Selector()
    static language({ language }: LanguageStateModel): string {
        return language;
    }

    async ngxsOnInit({ dispatch, getState }: StateContext<LanguageStateModel>) {
        const language = getState().language;

        if (language) {
            dispatch(new SetLanguage(language));
        } else {
            const osLanguage = (await Device.getLanguageCode()).value;

            dispatch(new SetLanguage(osLanguage.slice(0, 2)));
        }
    }

    @Action(SetLanguage)
    async setLanguage({ patchState }: StateContext<LanguageStateModel>, { language }: SetLanguage) {
        const dateFnsLocale = await import('date-fns/locale').then((m) => {
            if (language === 'en') {
                return m.enUS;
            } else {
                if (m[language]) {
                    return m[language];
                } else {
                    return m.enUS;
                }
            }
        });

        document.documentElement.lang = language;
        this.api.setDefaultHeader('Language', language);
        this.api.setDefaultHeader('Language-Fallback', 'en');
        this.translateService.use(language);
        this.dateFnsConfigService.setLocale(dateFnsLocale as any);
        patchState({ language });
    }
}
