import { Injectable } from '@angular/core';

import { State, StateContext, Action, Selector, Store } from '@ngxs/store';

import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import { orderBy } from 'natural-orderby';

import { AwareHttpService } from '@appbolaget/aware-http';
import { Navigation, Unit } from '@appbolaget/aware-model';

import {
    SetCurrentUnit,
    RefreshCurrentUnit,
    GetUnitByUuidAndSetCurrentUnit,
    GetMyUnits,
    GetOrganisation,
    GetTabsAndSidenav,
} from './unit.actions';
import { StateKey } from '@shared/enums';
import { BackgroundService } from '@shared/services';
import { environment } from '@env';
import { of, throwError } from 'rxjs';
import { AppConfig } from '@shared/interfaces';
import { NavController } from '@ionic/angular';
import { Logout } from './auth.actions';

export interface UnitStateModel {
    organisation: Unit;
    appConfig: AppConfig;
    masterUnit: Unit;
    myUnits: Unit[];
    currentUnit: Unit;
    currentUnitStartPageRoute: string;
    currentUnitBaseRoute: string;
    currentUnitModule: string;
    tabs: Navigation;
    sidenav: Navigation;
    hierarchy: Unit[];
}

const defaults: UnitStateModel = {
    organisation: null,
    appConfig: null,
    masterUnit: null,
    myUnits: null,
    currentUnit: null,
    currentUnitStartPageRoute: null,
    currentUnitBaseRoute: null,
    currentUnitModule: null,
    tabs: null,
    sidenav: null,
    hierarchy: null,
};

@State<UnitStateModel>({
    name: StateKey.Unit,
    defaults,
})
@Injectable({ providedIn: 'root' })
export class UnitState {
    constructor(
        private api: AwareHttpService,
        private store: Store,
        private backgroundService: BackgroundService,
        private navCtrl: NavController,
    ) {}

    @Selector()
    static myUnits({ myUnits }: UnitStateModel): Unit[] {
        return myUnits;
    }

    @Selector()
    static organisation({ organisation }: UnitStateModel): Unit {
        return organisation;
    }

    @Selector()
    static appConfig({ appConfig }: UnitStateModel): AppConfig {
        return appConfig;
    }

    @Selector()
    static currentUnit({ currentUnit }: UnitStateModel): Unit {
        return currentUnit;
    }

    @Selector()
    static masterUnit({ masterUnit }: UnitStateModel): Unit {
        return masterUnit;
    }

    @Selector()
    static currentUnitStartPageRoute({ currentUnitStartPageRoute }: UnitStateModel): string {
        return currentUnitStartPageRoute;
    }

    @Selector()
    static currentUnitBaseRoute({ currentUnitBaseRoute }: UnitStateModel): string {
        return currentUnitBaseRoute;
    }

    @Selector()
    static tabs({ tabs }: UnitStateModel): Navigation {
        return tabs;
    }

    @Selector()
    static sidenav({ sidenav }: UnitStateModel): Navigation {
        return sidenav;
    }

    @Selector()
    static hierarchy({ hierarchy }: UnitStateModel): Unit[] {
        return hierarchy;
    }

    @Action(GetOrganisation)
    getOrganisation({ patchState, dispatch }: StateContext<UnitStateModel>) {
        return this.api
            .get(`units/${environment.api.baseUnit}`)
            .toModel(Unit)
            .execute()
            .pipe(
                catchError((err) => {
                    dispatch([new Logout()]);

                    patchState({
                        currentUnit: null,
                        currentUnitStartPageRoute: null,
                        currentUnitBaseRoute: null,
                        masterUnit: null,
                        myUnits: null,
                        sidenav: null,
                        tabs: null,
                    });

                    this.navCtrl.navigateRoot('/');

                    return throwError(() => err);
                }),
                tap((organisation) => {
                    let appConfig;

                    try {
                        appConfig = JSON.parse(organisation.config).app;
                    } catch (error) {}

                    patchState({ organisation, appConfig });
                }),
            );
    }

    @Action(GetMyUnits)
    getMyUnits({ patchState }: StateContext<UnitStateModel>) {
        return this.api
            .get('grupper/units')
            .limit(100)
            .unit(environment.api.baseUnit)
            .parameter('scope', 'unit')
            .expand('attributes')
            .toCollection(Unit)
            .execute()
            .pipe(
                tap((result) => {
                    let { data } = result;

                    data = data.filter((unit) => unit.uuid !== environment.api.baseUnit);
                    data = orderBy(data, 'title');

                    patchState({ myUnits: data });
                }),
            );
    }

    @Action(GetUnitByUuidAndSetCurrentUnit)
    getUnitByUuidAndSetCurrentUnit({ dispatch }: StateContext<UnitStateModel>, { unitUuid }: GetUnitByUuidAndSetCurrentUnit) {
        return this.api
            .get(`units/${unitUuid}`)
            .expand(['media', 'attributes'])
            .toModel(Unit)
            .execute()
            .pipe(switchMap((unit) => dispatch(new SetCurrentUnit(unit))));
    }

    @Action(RefreshCurrentUnit)
    refreshCurrentUnit({ dispatch, getState }: StateContext<UnitStateModel>, { refreshNavigations }: RefreshCurrentUnit) {
        const { currentUnit } = getState();

        return dispatch(new SetCurrentUnit(currentUnit, refreshNavigations));
    }

    @Action(SetCurrentUnit)
    setCurrentUnit({ patchState, dispatch }: StateContext<UnitStateModel>, { unit, refreshNavigations }: SetCurrentUnit) {
        if (unit) {
            this.api.setUnit(unit.uuid);

            return this.api
                .get('units/parents')
                .with('attributes', 'media')
                .unit(unit.uuid)
                .toCollection(Unit)
                .execute()
                .pipe(
                    switchMap((hierarchy) => {
                        const masterUnit = this.findMasterUnit(hierarchy.data);

                        patchState({ masterUnit, hierarchy: hierarchy.data });
                        patchState({ currentUnit: hierarchy.data[0] });

                        const module = masterUnit.attr('module');
                        const currentUnitBaseRoute = `${hierarchy.data[0].uuid}/modules/${masterUnit.attr('module')}`;
                        let currentUnitStartPageRoute = currentUnitBaseRoute;

                        if (refreshNavigations) {
                            if (module === 'tabs') {
                                return dispatch(new GetTabsAndSidenav()).pipe(
                                    switchMap(() => {
                                        return this.store.select(UnitState.tabs).pipe(
                                            filter((tabs) => !!tabs),
                                            take(1),
                                            tap((tabs) => {
                                                const firstTabWithARoute = tabs.children.find((child) => child.post?.getMeta('route'));
                                                const firstTabUrl = firstTabWithARoute.post.getMeta('route');

                                                currentUnitStartPageRoute = `${currentUnitStartPageRoute}/${firstTabUrl}`;

                                                patchState({ currentUnitStartPageRoute, currentUnitBaseRoute, currentUnitModule: module });
                                            }),
                                        );
                                    }),
                                );
                            } else {
                                this.api.setUnit(environment.api.baseUnit);

                                patchState(defaults);
                            }
                        }

                        patchState({ currentUnitStartPageRoute, currentUnitBaseRoute, currentUnitModule: module });

                        return of(null);
                    }),
                );
        } else {
            this.api.setUnit(environment.api.baseUnit);

            patchState(defaults);
        }
    }

    @Action(GetTabsAndSidenav)
    getTabsAndSidenav({ patchState, getState }: StateContext<UnitStateModel>) {
        const { tabs, currentUnit } = getState();

        if (tabs) {
            this.backgroundService.stopTasksBasedOnNavigation(tabs);
        }

        return this.api
            .get('categories')
            .type('navigation')
            .with('children', 'children.attributes', 'children.meta', 'children.posts.meta')
            .toCollection(Navigation)
            .stream('up')
            .execute()
            .pipe(
                catchError((err) => throwError(() => err)),
                tap(({ data }) => {
                    const sidenavs = data.filter((navigation) => navigation.alias.includes('sidenav'));
                    const tabs = data.filter((navigation) => navigation.alias.includes('tabs'));
                    let sidenavItems = [];
                    let tabItems = [];

                    function parseItemsForUnit(unit: Unit) {
                        const foundSidenav = sidenavs.find((sidenav) => sidenav.alias.includes(unit.uuid));
                        const foundTabs = tabs.find((tab) => tab.alias.includes(unit.uuid));

                        if (foundSidenav) {
                            sidenavItems = sidenavItems.concat(orderBy(foundSidenav.children, 'position'));
                        }

                        if (foundTabs) {
                            tabItems = tabItems.concat(orderBy(foundTabs.children, 'position'));
                        }

                        const { hierarchy } = getState();
                        const indexOfCurrentUnit = hierarchy.findIndex((u) => unit.uuid === u.uuid);

                        if (hierarchy[indexOfCurrentUnit + 1]) {
                            parseItemsForUnit(hierarchy[indexOfCurrentUnit + 1]);
                        }
                    }

                    parseItemsForUnit(currentUnit);

                    patchState({ sidenav: new Navigation({ children: sidenavItems }), tabs: new Navigation({ children: tabItems }) });
                }),
            );
    }

    private findMasterUnit(hierarchy: Unit[]): Unit {
        for (const unit of hierarchy) {
            const themeAttribute = unit.attr('themeVariables', 'json');

            if (themeAttribute) {
                return unit;
            }
        }

        return null;
    }
}
