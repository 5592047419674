import { Component, Input, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { saveAs } from 'file-saver';

import { AwareHttpRequestModule, AwareHttpService } from '@appbolaget/aware-http';
import { File } from '@appbolaget/aware-model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '@env';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'app-file-viewer',
    templateUrl: 'file-viewer.component.html',
})
export class FileViewerComponent implements OnInit {
    @Input() fileUuid: string;

    file$: BehaviorSubject<File> = new BehaviorSubject(null);
    fileUrl: SafeResourceUrl;
    fileIsViewable = false;

    LOADING = true;
    DOWNLOADING = false;

    get selectedFileIframeUrl(): SafeResourceUrl {
        const selectedFile = this.file$.value;
        const part = selectedFile.getFilePath();
        const awareUrl = `${environment.api.url}/@media/${part}`;
        let url = `https://view.officeapps.live.com/op/embed.aspx?src=${awareUrl}`;

        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    get selectedFileUrl(): SafeResourceUrl {
        const selectedFile = this.file$.value;
        const part = selectedFile.getFilePath();
        const url = `${environment.api.url}/@media/${part}`;

        return url;
    }

    constructor(
        private api: AwareHttpService,
        private sanitizer: DomSanitizer,
        private modalCtrl: ModalController,
    ) {}

    ngOnInit() {
        if (this.fileUuid) {
            this.getFile();
        }
    }

    isPdf() {
        return this.file$.value.mime.includes('pdf');
    }

    getAwareUrl() {
        const selectedFile = this.file$.value;
        const part = selectedFile.getFilePath();
        const awareUrl = `${environment.api.url}/@media/${part}`;

        return awareUrl;
    }

    close() {
        this.modalCtrl.dismiss();
    }

    fileIsOfTypes(types: string[]) {
        const file = this.file$.value;

        for (const type of types) {
            if (file.mime.includes(type)) {
                return true;
            }
        }

        return false;
    }

    async downloadFile(save = true) {
        const file = this.file$.value;

        this.DOWNLOADING = true;

        try {
            const blob = await this.api
                .get(`@media/${file.getFilePath()}`)
                .responseType('blob')
                .module(AwareHttpRequestModule.Media)
                .execute()
                .toPromise();

            this.DOWNLOADING = false;

            if (save) {
                return saveAs(blob, file.title);
            }

            return blob;
        } catch (e) {}

        this.DOWNLOADING = false;
    }

    private getFile() {
        this.LOADING = true;

        this.api
            .get(`files/${this.fileUuid}`)
            .module(AwareHttpRequestModule.Media)
            .toModel(File)
            .execute()
            .pipe(
                tap((file) => {
                    this.file$.next(file);

                    if (file.isImage()) {
                        this.fileUrl = this.selectedFileUrl;
                        this.fileIsViewable = true;
                    } else if (
                        this.fileIsOfTypes([
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            'application/msword',
                            'pdf',
                        ])
                    ) {
                        this.fileUrl = this.selectedFileIframeUrl;
                        this.fileIsViewable = true;
                    }

                    this.LOADING = false;
                }),
            )
            .subscribe();
    }
}
