import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stripHTML',
})
export class StripHtmlPipe implements PipeTransform {
    transform(text: string): string {
        if (text) {
            return text.replace(/<(?:.|\s)*?>/g, '').replace(/&.+;/g, ' ');
        }

        return text;
    }
}
