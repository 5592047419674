<ion-select
    interface="popover"
    [label]="label | translate"
    labelPlacement="floating"
    [placeholder]="label | translate"
    [formControl]="languageControl"
    [cancelText]="cancelText | translate"
    [okText]="okText | translate"
    fill="solid"
    mode="md"
>
    @for (language of (appConfig$ | async)?.languages; track language) {
        <ion-select-option [value]="language.code">{{ language.label | translate }}</ion-select-option>
    }
</ion-select>
