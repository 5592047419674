@if (selfAware && !client ? (client$ | async) : client) {
  <div class="bg-light rounded-md flex flex-row gap-2 p-2 selected-border-color border-solid"
    [ngClass]="selected ? 'border-2 p-[6px]' : ''"
    [ngClass]="button ? 'hover:cursor-pointer hover:border-2 hover:p-[6px]' : ''">
    <app-client-avatar [clients]="selfAware && !client ? (client$ | async) : client"></app-client-avatar>
    <div class="flex flex-col justify-center h-12">
      @if (label) {
        <p class="ellipsis">{{ label | translate }}</p>
      } @else {
        <p class="ellipsis">{{ selfAware && (client$ | async)?.uuid === client.uuid ? ('COMMON.ME' | translate) : client.name
        }}</p>
      }
    </div>
  </div>
}

