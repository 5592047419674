import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

import { Client } from '@appbolaget/aware-model';

import { AuthState } from '@shared/state';

@Component({
    selector: 'app-client-avatar',
    templateUrl: 'client-avatar.component.html',
})
export class ClientAvatarComponent implements OnInit, OnDestroy {
    @Input() clients: Client | Client[];
    @Input() hideSelf = false;
    @Input() shape: 'circle' | 'square' | 'rounded' = 'circle';

    parsedClients: Client[];

    RENDER = false;

    constructor(private store: Store) {}

    ngOnInit() {
        const me = this.store.selectSnapshot(AuthState.client);

        if (Array.isArray(this.clients)) {
            this.parsedClients = this.clients;

            if (this.hideSelf && me) {
                this.parsedClients = this.clients.filter((c) => c.uuid !== me.uuid);
            }

            this.parsedClients = this.parsedClients.slice(0, 2);
        } else {
            this.parsedClients = [this.clients];
        }

        this.RENDER = true;
    }

    ngOnDestroy() {}
}
