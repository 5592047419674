import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { debounceTime, filter, takeWhile, tap } from 'rxjs/operators';

@Component({
    selector: 'app-search-input',
    templateUrl: 'search-input.component.html',
})
export class SearchInputComponent implements OnInit, OnDestroy {
    @ViewChild('searchInput') private searchInput;

    @Input() icon = 'search';
    @Input() debounce = 500;
    @Input() minLength = 0;
    @Input() placeholder = 'COMMON.SEARCH';

    @Output() valueChanged = new EventEmitter();

    searchControl = new UntypedFormControl('');

    LOADING = false;
    private ALIVE = true;

    get value() {
        return this.searchControl.value;
    }

    constructor() {}

    ngOnInit() {
        this.searchControl.valueChanges
            .pipe(
                takeWhile(() => this.ALIVE),
                debounceTime(this.debounce),
                filter((value) => value?.length >= this.minLength),
                tap((value) => {
                    this.valueChanged.next(value);
                }),
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    setLoading(value: boolean) {
        this.LOADING = value;
    }

    focus() {
        this.searchInput.nativeElement.focus();
    }
}
