import { Store } from '@ngxs/store';

import { Observable, of } from 'rxjs';

import { Logout } from '@shared/state';

export function checkLogoutParam(store: Store): Observable<any> {
    const params = new URLSearchParams(window.location.search);

    if (params.get('signout')) {
        return store.dispatch(new Logout());
    }

    return of(true);
}
