import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngxs/store';
import { AuthState, RefreshTokenAndClient } from '@shared/state';
import { BehaviorSubject, catchError, filter, map, Observable, switchMap, take, throwError, timeout } from 'rxjs';

const jwtHelper = new JwtHelperService();

@Injectable()
export class AwareAuthInterceptor implements HttpInterceptor {
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    IS_REFRESHING_TOKEN: boolean;

    constructor(private store: Store) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.store.selectSnapshot(AuthState.token);

        if (!token || req.url.endsWith('/authenticate/refresh')) {
            return next.handle(req).pipe(catchError(this._handleError.bind(this)));
        }

        if (this.IS_REFRESHING_TOKEN) {
            return this.tokenSubject.pipe(
                filter((token) => !!token),
                take(1),
                switchMap((token) => {
                    return next.handle(this._modifyRequest(req, token)).pipe(catchError(this._handleError.bind(this)));
                }),
            );
        }

        const decodedToken = jwtHelper.decodeToken(token);
        const expireDate = new Date(decodedToken.exp * 1000);
        const modifiedExpireDate = new Date(expireDate.getTime() - 1000 * 60 * 1); // 1 minute before expire
        const now = new Date();
        const shouldRefresh = modifiedExpireDate.getTime() < now.getTime();

        if (shouldRefresh) {
            this.IS_REFRESHING_TOKEN = true;
            this.tokenSubject.next(null);

            return this._refreshTokenAndRetry(req, next).pipe(catchError(this._handleError.bind(this)));
        }

        return next.handle(req).pipe(timeout(30000), catchError(this._handleError.bind(this)));
    }

    private _handleError(error: HttpErrorResponse) {
        return throwError(() => error.error);
    }

    private _refreshTokenAndRetry(req: HttpRequest<any>, next: HttpHandler) {
        return this.store.dispatch(new RefreshTokenAndClient()).pipe(
            map(() => this.store.selectSnapshot(AuthState.token)),
            switchMap((newToken) => {
                this.tokenSubject.next(newToken);
                this.IS_REFRESHING_TOKEN = false;

                return next.handle(this._modifyRequest(req, newToken));
            }),
            catchError((error) => {
                this.IS_REFRESHING_TOKEN = false;
                this.tokenSubject.next(null);

                return throwError(() => error);
            }),
        );
    }

    private _modifyRequest(req: HttpRequest<any>, token: string) {
        if (!req.headers.has('token')) {
            return req;
        }

        return req.clone({
            setHeaders: {
                token,
            },
        });
    }
}
