<div
    (click)="toggle()"
    class="rounded-md flex flex-row gap-2 px-2 selected-border-color border-solid md:hover:cursor-pointer md:hover:border-2 md:hover:p-[6px]"
    [ngClass]="{
        'bg-light': color === 'gray',
        'bg-white': color === 'white',
        'py-1': size === 'small',
        'py-2': size === 'large'
    }"
>
    <div class="p-2 flex justify-center items-center">
        @if (controlName) {
            <input
                [disabled]="disabled"
                type="checkbox"
                #checkbox
                class="h-5 w-5 form-checkbox checked:bg-primary rounded"
                [ngClass]="{
                    'border-[#f0f1f3] border-2': color === 'white'
                }"
                [checked]="checked"
                [formControlName]="controlName"
                (click)="toggle($event)"
            />
        }

        @if (!controlName) {
            <input
                [disabled]="disabled"
                type="checkbox"
                #checkbox
                class="h-5 w-5 form-checkbox checked:bg-primary rounded"
                [ngClass]="{
                    'border-[#f0f1f3] border-2': color === 'white'
                }"
                [checked]="checked"
                (click)="toggle($event)"
            />
        }
    </div>

    <div
        class="flex flex-col justify-center whitespace-pre-wrap"
        [ngClass]="{
            'min-h-[48px]': size === 'large'
        }"
    >
        <p>{{ text | translate }}</p>
    </div>
</div>
