<ion-header mode="md">
  <ion-toolbar>
    <ion-buttons slot="start">
      @if (!canGoBack && menuButton) {
        <ion-menu-button></ion-menu-button>
      }
      @if (canGoBack && !closeButton) {
        <ion-back-button [text]="'COMMON.BACK' | translate"
        [defaultHref]="[(currentUnit$ | async)?.uuid, 'tabs', 'launchpad'].join('/')"></ion-back-button>
      }
    </ion-buttons>

    <ion-title>{{ (currentUnit$ | async)?.title }}</ion-title>

    @if (!menuButton && (currentUnit$ | async)?.image; as image) {
      <img [src]="image | imageByUuid : 300"
        class="h-6 object-contain mr-5" slot="end" />
    }

    @if (closeButton) {
      <ion-buttons slot="end">
        <ion-button class="normal-case" (click)="closeModal()">
          <ion-icon name="close"></ion-icon>
          <!-- <p *ngIf="selectedFiles.length">Välj {{ selectedFiles.length + (selectedFiles.length === 1 ? ' fil' : ' filer') }}</p> -->
        </ion-button>
      </ion-buttons>
    }

    @if (!closeButton && (currentUnit$ | async)?.image; as image) {
      <img [src]="image | imageByUuid : 300"
        class="h-6 object-contain mr-5" slot="end" />
    }
  </ion-toolbar>
</ion-header>

<ion-content class="max-h-[calc(100%-56px)]">
  @if (LOADING) {
    <app-indeterminate-loader></app-indeterminate-loader>
  }

  @if (UPLOADING_FILES) {
    <div
      class="w-full h-12 flex flex-row items-center gap-2 border-gray-500 border-solid border-b border-opacity-30 last:border-0 outline-none text-center text-[#828282] justify-center"
      >
      <ion-icon name="sync-outline" class="animate-spin"></ion-icon>
      {{ 'PAGES.MEDIA.UPLOADING_FILES' | translate }}
    </div>
  }

  <!-- <div class="py-2 px-5">
  <app-search-input placeholder="Sök filer och mappar..."></app-search-input>
</div> -->

@if (stack.length) {
  <div class="h-14 w-full relative flex flex-row items-center">
    <ion-button class="back-button" fill="clear" size="small" shape="round" (click)="popStack()"
      [disabled]="!stack.length">
      <ion-icon color="primary" [name]="stack.length > 1 ? 'arrow-back-outline' : 'home-outline'"></ion-icon>
    </ion-button>
    <div class="w-full h-full overflow-x-auto flex flex-row items-center pr-4" #stackInner>
      @for (stackItem of stack; track stackItem) {
        <ion-button fill="clear" size="small" (click)="goToBucket(stackItem, true)"
          [disabled]="!itemIsntLastInStack(stackItem)" class="text-black">
          {{ stackItem.title }}
        </ion-button>
        @if (itemIsntLastInStack(stackItem)) {
          <div class="text-lg text-[#828282]">></div>
        }
      }
    </div>
  </div>
}

@if (!currentBucket?.children?.length && !currentBucket?.files?.length && !LOADING) {
  <div
    class="h-full flex items-center justify-center text-center text-[#828282]">
    {{ 'PAGES.MEDIA.NO_FILES_FOLDERS' | translate }}
  </div>
}

<div class="overflow-y-auto pb-16" [ngClass]="stack.length ? 'max-h-[calc(100%-56px)]' : 'max-h-full'">
  <ion-list [style.margin-bottom]="!page && '70px'">
    @if (currentBucket?.children?.length) {
      @for (bucket of currentBucket.children; track trackByUuid($index, bucket)) {
        <ion-item button
          (click)="goToBucket(bucket)">
          <ion-icon slot="start" name="folder-outline"></ion-icon>
          <ion-label>{{ bucket.title }}</ion-label>
          <ion-button slot="end" fill="clear" (click)="showBucketActions(bucket, $event)" color="primary" class="h-4/5">
            <ion-icon name="ellipsis-horizontal" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-item>
      }
    }

    @if (currentBucket?.files?.length) {
      @for (file of currentBucket.files; track trackByUuid($index, file)) {
        <ion-item button (click)="fileClicked(file)"
          [ngClass]="{
            'file-selected': isFileSelected(file)
          }">
          @if (!file.isImage()) {
            <ion-icon slot="start" name="document-outline"></ion-icon>
          }
          @if (file.isImage()) {
            <ion-avatar slot="start">
              <img [src]="file.uuid | imageByUuid : 150" class="w-full h-full rounded-[4px]" />
            </ion-avatar>
          }
          <ion-label>{{ file.title }}</ion-label>
          <ion-button slot="end" fill="clear" (click)="showFileActions(file, $event)" color="primary" class="h-4/5">
            <ion-icon name="ellipsis-horizontal" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-item>
      }
    }
  </ion-list>
</div>

<ion-fab [style.margin-bottom]="!page && '70px'" horizontal="end" vertical="bottom" slot="fixed">
  <ion-fab-button color="primary" (click)="showAddActionSheet()">
    <ion-icon name="add"></ion-icon>
  </ion-fab-button>
</ion-fab>

@if (selectedFiles.length) {
  <div class="w-[90%] fixed px-5 pb-4 bottom-0 left-0 flex justify-center z-50">
    <div class="w-full xl:w-2/3">
      <ion-button shape="round" color="primary" expand="block" (click)="closeModal(true)" class="normal-case">
        {{ 'PAGES.MEDIA.CHOOSE' | translate }} {{ ' ' + selectedFiles.length + ' ' }}
        {{ selectedFiles.length === 1 ? ('PAGES.MEDIA.FILE' | translate).toLowerCase() : ('PAGES.MEDIA.FILES' |
        translate).toLowerCase() }}
      </ion-button>
    </div>
  </div>
}
</ion-content>