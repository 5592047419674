import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavController } from '@ionic/angular';

import { Actions, NgxsModule, Store } from '@ngxs/store';
import { NgxsAsyncStoragePluginModule } from '@appbolaget/ngxs-async-storage';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { DateFnsModule, DateFnsConfigurationService } from 'ngx-date-fns';
import { sv } from 'date-fns/locale';

import { AwareHttpModule, AwareHttpRequestModule, AwareHttpService } from '@appbolaget/aware-http';
import { GesturesModule } from '@appbolaget/gestures';
import { AwareSecurityModule, AwareSecurityPermissionProvider } from '@appbolaget/aware-security';
import { AwareAppVersionModule } from '@appbolaget/app-version';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from '@app/app.component';
import { appInit } from '@shared/helpers';
import { AppRoutingModule } from '@app/app-routing.module';
import {
    AppState,
    AuthState,
    customStateDeserializer,
    customStateSerializer,
    LanguageState,
    NgxsAsyncStorageEngine,
    UnitState,
} from '@shared/state';
import { environment } from '@env';
import { PermissionProvider, TranslationLoader, AwareAuthInterceptor } from '@shared/misc';
import { SharedModule } from '@shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AwarePushModule } from '@shared/modules/push/aware-push.module';

import { register as registerSwiper } from 'swiper/element/bundle';

registerSwiper();

const swedishConfig = new DateFnsConfigurationService();
swedishConfig.setLocale(sv);

@NgModule({
    declarations: [AppComponent],
    imports: [
        HttpClientModule,
        BrowserModule,
        IonicModule.forRoot({
            mode: 'ios',
        }),
        DateFnsModule.forRoot(),
        AwareSecurityModule.forRoot(),
        AwarePushModule,
        AppRoutingModule,
        GesturesModule,
        SharedModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
        }),
        AwareAppVersionModule.forRoot({
            appId: environment.app.appId,
            unitId: environment.api.baseUnit,
        }),
        AwareHttpModule.forRoot({
            defaultEnv: {
                url: environment.api.url,
                name: 'default',
            },
            defaultHeaders: {
                app: environment.app.appId,
                language: 'sv',
                location: 'se',
                module: AwareHttpRequestModule.Cms,
                source: environment.api.source,
                timezone: 'Europe/Stockholm',
                unit: environment.api.baseUnit,
            },
        }),
        NgxsModule.forRoot([AppState, AuthState, LanguageState, UnitState], {
            developmentMode: !environment.production,
        }),
        NgxsAsyncStoragePluginModule.forRoot(NgxsAsyncStorageEngine, {
            deserialize: customStateDeserializer,
            serialize: customStateSerializer,
            key: [AuthState, LanguageState, UnitState],
        }),
        NgxsLoggerPluginModule.forRoot({
            disabled: environment.production,
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        BrowserAnimationsModule,
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: AwareSecurityPermissionProvider, useClass: PermissionProvider },
        { provide: DateFnsConfigurationService, useValue: swedishConfig },
        { provide: TranslateLoader, useClass: TranslationLoader },
        {
            provide: APP_INITIALIZER,
            useFactory: appInit,
            deps: [Store, Actions, NavController, AwareHttpService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AwareAuthInterceptor,
            deps: [Store],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
