import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { interval, Observable, Subject } from 'rxjs';

import { Client, Navigation } from '@appbolaget/aware-model';
import { AwareSecurityService } from '@appbolaget/aware-security';

import { AuthState, GetUnitInvites, UnitState } from '@shared/state';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BackgroundService {
    tasks: { [key: string]: { stop$: Subject<void>; interval: Observable<unknown> } } = {};

    CLIENT_TASKS_RUNNING = false;

    private navigationTasks = [];

    private clientTasks = [
        {
            identifier: 'getInvites',
            interval: 60000,
            actions: [new GetUnitInvites()],
            loginRequired: true,
        },
    ];

    constructor(
        private store: Store,
        private securityService: AwareSecurityService,
    ) {
        this.store
            .select(AuthState.client)
            .pipe(
                tap((client: Client) => {
                    if (client && !this.CLIENT_TASKS_RUNNING) {
                        this.startTasksBasedOnClient();
                    } else if (!client) {
                        this.stopTasksBasedOnClient();
                    }
                }),
            )
            .subscribe();
    }

    startTasksBasedOnNavigation(navigation: Navigation) {
        const { currentUnit } = this.store.selectSnapshot(UnitState);
        const { client } = this.store.selectSnapshot(AuthState);

        for (const child of navigation.children) {
            const loginRequired = Boolean(Number(navigation.attribute('login_required')?.simple));
            const requiredPermissions = navigation.attr('login_required', 'json');
            const hasPermission = this.securityService.isGrantedAny(requiredPermissions, currentUnit.uuid);
            const { post } = child;

            if (post) {
                const route = post.getMeta('route');

                for (const navTask of this.navigationTasks) {
                    const task: any = {
                        stop$: new Subject(),
                    };

                    task.interval = interval(navTask.interval).pipe(
                        takeUntil(task.stop$),
                        tap(() => {
                            this.store.dispatch(navTask.actions);
                        }),
                    );

                    if (route === navTask.route) {
                        if ((navTask.loginRequired || loginRequired) && client && hasPermission) {
                            this.tasks[navTask.identifier] = task;
                            this.tasks[navTask.identifier].interval.subscribe();
                        } else if (!navTask.loginRequired) {
                            this.tasks[navTask.identifier] = task;
                            this.tasks[navTask.identifier].interval.subscribe();
                        }
                    }
                }
            }
        }
    }

    stopTasksBasedOnNavigation(navigation: Navigation) {
        // const { currentUnit } = this.store.selectSnapshot(UnitState);

        for (const child of navigation.children) {
            // const loginRequired = Boolean(Number(navigation.attribute('login_required')?.simple));
            // const requiredPermissions = navigation.attr('login_required', 'json');
            // const hasPermission = this.securityService.isGrantedAny(requiredPermissions, currentUnit.uuid);
            const { post } = child;

            if (post) {
                const route = post.getMeta('route');

                for (const navTask of this.navigationTasks) {
                    const task: any = {
                        stop$: new Subject(),
                    };

                    task.interval = interval(navTask.interval).pipe(
                        takeUntil(task.stop$),
                        tap(() => {
                            this.store.dispatch(navTask.actions);
                        }),
                    );

                    if (route === navTask.route && this.tasks[navTask.identifier]) {
                        this.tasks[navTask.identifier].stop$.next();
                    }
                }
            }
        }
    }

    private startTasksBasedOnClient() {
        this.CLIENT_TASKS_RUNNING = true;

        for (const clientTask of this.clientTasks) {
            const task: any = {
                stop$: new Subject(),
            };

            task.interval = interval(clientTask.interval).pipe(
                takeUntil(task.stop$),
                tap(() => {
                    this.store.dispatch(clientTask.actions);
                }),
            );

            this.tasks[clientTask.identifier] = task;
            this.tasks[clientTask.identifier].interval.subscribe();
        }
    }

    private stopTasksBasedOnClient() {
        this.CLIENT_TASKS_RUNNING = false;

        for (const clientTask of this.clientTasks) {
            if (this.tasks[clientTask.identifier]) {
                this.tasks[clientTask.identifier].stop$.next();
            }
        }
    }
}
