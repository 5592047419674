<div class="flex flex-col gap-1">
  @if (label) {
    <p class="small-header">{{ label | translate }}</p>
  }

  @if (controlName) {
    <textarea
      class="outline-none resize-none w-full p-2 gap-2 border-2 border-solid rounded-md"
      [class.min-height]="minHeight + 'px'"
      [ngClass]="HAS_ERRORS ? 'border-danger' : 'border-[#f0f1f3]'"
      [ngClass]="{ 'overflow-hidden': autoGrow }"
      [placeholder]="placeholder ?? '' | translate"
      [formControlName]="controlName"
      #textarea
    ></textarea>
  }

  @if (!controlName) {
    <textarea
      class="outline-none resize-none w-full p-2 gap-2 border-2 border-solid rounded-md"
      [class.min-height]="minHeight + 'px'"
      [ngClass]="HAS_ERRORS ? 'border-danger' : 'border-[#f0f1f3]'"
      [ngClass]="{ 'overflow-hidden': autoGrow }"
      [placeholder]="placeholder ?? '' | translate"
      #textarea
    ></textarea>
  }
</div>
