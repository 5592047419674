<div
    class="flex flex-row h-full aspect-square p-2 gap-2 border-2 border-solid rounded-md border-[#f0f1f3] justify-center items-center"
    [ngClass]="{
        'hover:border-primary cursor-pointer': button
    }"
>
    @if (icon) {
        <ion-icon
            class="text-primary"
            [name]="icon"
            [ngClass]="{
                'text-2xl': size === 'small',
                'text-4xl': size === 'large'
            }"
        />
    }
</div>
