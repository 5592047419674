import { AsyncStorageEngine } from '@appbolaget/ngxs-async-storage';

import { Preferences } from '@capacitor/preferences';

import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

export class NgxsAsyncStorageEngine implements AsyncStorageEngine {
    setItem(key: any, value: any): Observable<any> {
        return from(
            Preferences.set({
                key,
                value,
            }),
        );
    }

    getItem(key: any): Observable<any> {
        return from(
            Preferences.get({
                key,
            }),
        ).pipe(map((val) => val.value));
    }

    removeItem(key: any): Observable<any> {
        return from(
            Preferences.remove({
                key,
            }),
        );
    }

    clear(): Observable<any> {
        return from(Preferences.clear());
    }

    key(val: number): Observable<any> {
        return from(Preferences.keys().then(({ keys }) => keys[val]));
    }

    length(): Observable<any> {
        return from(Preferences.keys().then(({ keys }) => keys.length));
    }
}
