import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngxs/store';

import { Subject, firstValueFrom } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

import { Navigation } from '@appbolaget/aware-model';

import { AuthState, UnitState } from '@shared/state';
import { AwareSecurityService } from '@appbolaget/aware-security';

@Directive({
    selector: '[shouldRenderNavigation]',
})
export class ShouldRenderNavigationDirective implements OnDestroy {
    IS_IN_VIEW = false;
    destroy$ = new Subject<void>();

    @Input() set shouldRenderNavigation(navigation: Navigation) {
        this.store
            .select(AuthState.client)
            .pipe(
                takeUntil(this.destroy$),
                tap(async (client) => {
                    const { currentUnit } = this.store.selectSnapshot(UnitState);

                    let loginRequired = Boolean(Number(navigation.attribute('login_required')?.simple));
                    let hasRequiredRoles = false;
                    let requiredRoles = null;
                    const navigationType = navigation.getMeta('type');

                    if (navigation.post?.type === 'approute') {
                        try {
                            const parameters = JSON.parse(navigation.post.getMeta('parameters'));

                            if (parameters?.loginRequired) {
                                loginRequired = true;
                            }
                        } catch (error) {}
                    }

                    try {
                        requiredRoles = JSON.parse(navigation.attr('selectedRoles', 'json'));
                    } catch (error) {
                        requiredRoles = null;
                    }

                    const hasOwnerRole = client?.roles?.some((role) => role.alias === `${currentUnit.uuid}:role:owner`);
                    const isGod = await firstValueFrom(this.awareSecurityService.isGod());

                    if (hasOwnerRole || isGod) {
                        hasRequiredRoles = true;
                    } else if (requiredRoles?.length) {
                        loginRequired = true;

                        for (const requiredRole of requiredRoles) {
                            const found = client?.roles.some((role) => role.uuid === requiredRole);

                            if (found) {
                                hasRequiredRoles = true;

                                break;
                            }
                        }
                    } else {
                        hasRequiredRoles = true;
                    }

                    if (!navigationType) {
                        this.remove();
                    } else if (!loginRequired) {
                        this.render();
                    } else if (loginRequired && client && hasRequiredRoles) {
                        this.render();
                    } else {
                        this.remove();
                    }
                }),
            )
            .subscribe();
    }

    constructor(
        private store: Store,
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private awareSecurityService: AwareSecurityService,
    ) {}

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private remove() {
        this.viewContainerRef.clear();
        this.IS_IN_VIEW = false;
    }

    private render() {
        if (!this.IS_IN_VIEW) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
            this.IS_IN_VIEW = true;
        }
    }
}
