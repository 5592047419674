import { Store } from '@ngxs/store';

import { isValidUUID } from '@appbolaget/helpers';

import { Observable, of } from 'rxjs';

import { RefreshCurrentUnit, UnitState } from '@shared/state';

export function refreshCurrentUnit(store: Store): Observable<any> {
    const { currentUnit } = store.selectSnapshot(UnitState);
    let pathName = window.location.pathname;

    if (pathName.startsWith('/')) {
        pathName = pathName.substring(1);
    }

    const pathParts = window.location.pathname.split('/');

    if (currentUnit && (pathParts[0] === currentUnit.uuid || !isValidUUID(pathParts[0]))) {
        return store.dispatch(new RefreshCurrentUnit());
    }

    return of(null);
}
