import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-icon-card',
    templateUrl: 'icon-card.component.html',
    styleUrls: ['icon-card.component.scss'],
    host: {
        '[class.block]': 'isSmall()',
        '[class.w-1/4]': 'isSmall()',
    },
})
export class IconCardComponent implements OnInit, OnDestroy {
    @Input() label: string;
    @Input() description: string;
    @Input() icon: string;
    @Input() selected = false;
    @Input() loading = false;
    @Input() button = false;
    @Input() size: 'small' | 'large' = 'large';
    @Input() dismissable = false;
    @Input() badgeLabel: string | number;
    @Input() badge: boolean = false;
    @Input() dragHandle: boolean = false;

    @Output() dismissed = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {}

    isSmall() {
        return this.size === 'small';
    }
}
