<ion-app>
    <ion-split-pane contentId="main" (ionSplitPaneVisible)="splitPaneVisibleChanged($event)">
        <ion-menu contentId="main" type="overlay" class="xl:w-96" [disabled]="!(currentUnit$ | async)">
            <ion-content class="border-[rgb(229,231,235)] border-solid" [ngClass]="splitPaneVisible ? 'border-r-[1px]' : ''">
                <div class="flex justify-center items-center w-full mt-[calc(var(--ion-safe-area-top)+20px)]">
                    @if ((currentUnit$ | async)?.image ?? (masterUnit$ | async)?.image; as image) {
                        <img [src]="image | imageByUuid: 500" class="mb-5 px-5 max-h-32" [pressGesture]="reloadGesture" />
                    }
                </div>

                <div class="w-full flex flex-col">
                    @if (pwaUpdateAvailable$ | async) {
                        <div class="border-[#f1f0f3] border-2 rounded-md p-2 flex flex-row items-center gap-2" (click)="reload()">
                            <div class="flex justify-center items-center w-1/5">
                                <ion-icon name="information-circle-outline" class="text-3xl"></ion-icon>
                            </div>
                            <div class="w-4/5 h-full text-sm">Det finns en ny uppdatering, klicka här för att ladda om sidan.</div>
                        </div>
                    }

                    <ion-list [inset]="true">
                        <ion-menu-toggle [autoHide]="false" routerDirection="forward" [routerLink]="[currentUnitStartPageRoute$ | async]">
                            <ion-item [button]="true" color="light">
                                <ion-icon name="home-outline" slot="start"></ion-icon>
                                <ion-label>{{ 'COMPONENTS.SIDENAV.HOME' | translate }}</ion-label>
                            </ion-item>
                        </ion-menu-toggle>
                    </ion-list>

                    @if ((sidenav$ | async)?.children; as sidenav) {
                        <ion-list [inset]="true">
                            @for (item of sidenav | orderBy: 'position'; track item.uuid) {
                                <ion-menu-toggle [autoHide]="false" *shouldRenderNavigation="item" [navigationClick]="item">
                                    <ion-item [button]="true" color="light">
                                        @if (item.getMeta('icon'); as icon) {
                                            <ion-icon [name]="icon" slot="start"></ion-icon>
                                        }

                                        <ion-label>{{ item.title }}</ion-label>
                                    </ion-item>
                                </ion-menu-toggle>
                            }
                        </ion-list>
                    }

                    <ion-list inset="true">
                        @if (client$ | async) {
                            <ion-menu-toggle [autoHide]="false">
                                <ion-item routerLink="/settings/my-units-settings" routerDirection="forward" lines="none" color="light">
                                    <ion-icon name="heart-outline" slot="start"></ion-icon>
                                    {{ 'PAGES.SETTINGS.MY_GROUPS' | translate }}
                                </ion-item>
                            </ion-menu-toggle>
                        }

                        <ion-menu-toggle [autoHide]="false" *awIsGranted="'group.manage'">
                            <ion-item
                                button
                                lines="none"
                                routerDirection="forward"
                                [routerLink]="['/', (currentUnit$ | async)?.uuid, 'admin']"
                                color="light"
                            >
                                <ion-icon name="build-outline" slot="start"></ion-icon>
                                {{ 'COMPONENTS.SIDENAV.MANAGE_GROUP' | translate }}
                            </ion-item>
                        </ion-menu-toggle>

                        <ion-menu-toggle [autoHide]="false">
                            <ion-item button lines="none" routerDirection="forward" [routerLink]="['/settings']" color="light">
                                <ion-icon name="settings-outline" slot="start"></ion-icon>
                                {{ 'COMMON.SETTINGS' | translate }}

                                @if (unitInvitesAmount$ | async; as unitInvitesAmount) {
                                    <ion-badge slot="end" color="primary" class="rounded-full px-2 py-1 text-xs">{{
                                        unitInvitesAmount
                                    }}</ion-badge>
                                }
                            </ion-item>
                        </ion-menu-toggle>

                        @if (!(client$ | async)) {
                            <ion-menu-toggle [autoHide]="false">
                                <ion-item routerLink="/auth" routerDirection="forward" lines="none">
                                    <ion-icon name="log-in-outline" slot="start"></ion-icon>
                                    {{ 'COMMON.SIGN_IN' | translate }}
                                </ion-item>
                            </ion-menu-toggle>
                        }
                    </ion-list>
                </div>
            </ion-content>
        </ion-menu>

        <ion-router-outlet id="main"></ion-router-outlet>
    </ion-split-pane>
</ion-app>
