import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ModalController, NavController } from '@ionic/angular';

import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Client } from '@appbolaget/aware-model';
import { AwareHttpRequestModule, AwareHttpService } from '@appbolaget/aware-http';

import { AuthState, UnitState } from '@shared/state';
import { ToastService } from '@shared/services';

@Component({
    selector: 'app-client-profile',
    templateUrl: 'client-profile.component.html',
})
export class ClientProfileComponent implements OnInit, OnDestroy {
    @Input() uuid: string;
    @Input() popOnDismiss = false;

    client$: BehaviorSubject<Client> = new BehaviorSubject(null);

    ALIVE = true;

    LOADING = false;

    constructor(
        private store: Store,
        private api: AwareHttpService,
        private modalCtrl: ModalController,
        private toastService: ToastService,
        private navCtrl: NavController,
    ) {}

    ngOnInit() {
        this.getClient();
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    close() {
        this.modalCtrl.dismiss();
    }

    call() {
        const client = this.client$.value;

        window.open(`tel:${client.phone}`);
    }

    mail() {
        const client = this.client$.value;

        window.open(`mailto:${client.email}`);
    }

    private getClient() {
        const me = this.store.selectSnapshot(AuthState.client);

        this.LOADING = true;

        this.api
            .get(`clients/${this.uuid || me.uuid}`)
            .expand(['attributes', 'communications'])
            .toModel(Client)
            .execute()
            .pipe(
                tap((client: Client) => {
                    this.client$.next(client);

                    this.LOADING = false;
                }),
            )
            .subscribe();
    }
}
