import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Unit } from '@appbolaget/aware-model';

@Component({
    selector: 'app-unit-card',
    templateUrl: 'unit-card.component.html',
    styleUrls: ['unit-card.component.scss'],
})
export class UnitCardComponent implements OnInit, OnDestroy {
    @Input() unit: Unit;
    @Input() label: string;
    @Input() description: string;
    @Input() selected = false;
    @Input() button = false;
    @Input() showNavigationArrow = false;

    ngOnInit() {}

    ngOnDestroy() {}
}
