import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { UnitGuard } from '@shared/guards';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'modules/tabs',
        pathMatch: 'full',
    },
    {
        path: 'public',
        loadChildren: () => import('@public/views/public.module').then((m) => m.PublicPageModule),
    },
    {
        path: 'auth',
        loadChildren: () => import('@shared/views/auth/auth.module').then((m) => m.AuthPageModule),
    },
    {
        path: 'settings',
        loadChildren: () => import('@shared/views/settings/settings.module').then((m) => m.SettingsPageModule),
    },
    {
        path: ':unitUuid',
        children: [
            {
                path: 'finalize-invite',
                loadChildren: () => import('@public/views/finalize-invite/finalize-invite.module').then((m) => m.FinalizeInvitePageModule),
            },
            {
                path: '',
                canActivate: [UnitGuard],
                children: [
                    {
                        path: 'navigation',
                        loadChildren: () => import('@shared/views/navigation/navigation.module').then((m) => m.NavigationPageModule),
                    },
                    {
                        path: 'post/:uuid',
                        loadChildren: () => import('@shared/views/post/post.module').then((m) => m.PostPageModule),
                    },
                    {
                        path: 'components',
                        children: [
                            {
                                path: 'news',
                                loadChildren: () => import('@public/views/news/news.module').then((m) => m.NewsPageModule),
                            },
                            {
                                path: 'media',
                                loadChildren: () => import('@shared/views/media/media.module').then((m) => m.MediaPageModule),
                            },
                            {
                                path: 'checklists',
                                loadChildren: () =>
                                    import('@public/views/checklists/checklists.module').then((m) => m.ChecklistsPageModule),
                            },
                            {
                                path: 'forms',
                                loadChildren: () => import('@public/views/forms/forms.module').then((m) => m.FormsPageModule),
                            },
                            {
                                path: 'contacts',
                                loadChildren: () => import('@public/views/contacts/contacts.module').then((m) => m.ContactsPageModule),
                            },
                            {
                                path: 'group-info',
                                loadChildren: () => import('@public/views/unit-info/unit-info.module').then((m) => m.UnitInfoPageModule),
                            },
                            {
                                path: 'sub-units',
                                loadChildren: () => import('@public/views/sub-units/sub-units.module').then((m) => m.SubUnitsPageModule),
                            },
                            {
                                path: 'e-learning',
                                loadChildren: () => import('@public/views/e-learning/e-learning.module').then((m) => m.ELearningModule),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'admin',
                loadChildren: () => import('@admin/views/admin.module').then((m) => m.AdminPageModule),
            },
            {
                path: 'modules',
                canActivate: [UnitGuard],
                children: [
                    {
                        path: 'tabs',
                        loadChildren: () => import('@public/modules/tabs/tabs.module').then((m) => m.TabsPageModule),
                    },
                ],
            },
        ],
    },
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
