import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
    constructor(private toastCtrl: ToastController, private translateService: TranslateService) {}

    async info(options: ToastOptions | string) {
        if (typeof options === 'string') {
            options = { message: options };
        }

        const toastOptions = this.translateOptions({
            duration: 3000,
            position: 'top',
            buttons: [{ icon: 'close' }],
            ...options,
        });

        (await this.toastCtrl.create(toastOptions)).present();
    }

    async success(options: ToastOptions | string) {
        if (typeof options === 'string') {
            options = { message: options };
        }

        const toastOptions = this.translateOptions({
            duration: 3000,
            position: 'top',
            color: 'success',
            buttons: [{ icon: 'close' }],
            ...options,
        });

        (await this.toastCtrl.create(toastOptions)).present();
    }

    async error(options: ToastOptions | string) {
        if (typeof options === 'string') {
            options = { message: options };
        }

        const toastOptions = this.translateOptions({
            duration: 3000,
            position: 'top',
            color: 'danger',
            buttons: [{ icon: 'close' }],
            ...options,
        });

        (await this.toastCtrl.create(toastOptions)).present();
    }

    private translateOptions(options: ToastOptions) {
        if (options.message) {
            options.message = this.translateService.instant(options.message);
        }

        if (options.header) {
            options.header = this.translateService.instant(options.header);
        }

        if (options.buttons) {
            options.buttons = options.buttons.map((button) => {
                if (button.text) {
                    button.text = this.translateService.instant(button.text);
                }

                return button;
            });
        }

        return options;
    }
}

export interface ToastOptions {
    header?: string;
    message?: string;
    duration?: number;
    color?: string;
    position?: 'top' | 'bottom' | 'middle';
    buttons?: { handler?: () => any; text?: string; icon?: string }[];
}
