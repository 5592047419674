export class Login {
    static type = '[Auth] Attempt login';

    constructor(public credentials: LoginCredentials) {}
}

export class LoginSuccessful {
    static type = '[Auth] Login successful';
}

export class LoginUnsuccessful {
    static type = '[Auth] Login unsuccessful';
}

export class Logout {
    static type = '[Auth] Logout';
}

export class RefreshTokenAndClient {
    static type = '[Auth] Refresh token';
}

export class SetToken {
    static type = '[Auth] Set token';

    constructor(public token: string) {}
}

export class GetUnitInvites {
    static type = '[Auth] Get unit invites';
}

interface LoginCredentials {
    username: string;
    password: string;
}
