import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env';

@Pipe({
    name: 'imageByUuid',
})
export class ImageByUuidPipe implements PipeTransform {
    transform(uuid: string, width?: number) {
        const baseUrl = `${environment.api.url}/@media/${uuid}`;

        if (width) {
            return `${baseUrl}?w=${width}`;
        }

        return baseUrl;
    }
}
