import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
    transform(str: string): string {
        if (str) {
            return str.replace(
                /((((http|https):\/\/)|www\.)[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g,
                '<a target="_blank" href="$1">$1</a>',
            );
        }

        return str;
    }
}
