import { Pipe, PipeTransform } from '@angular/core';

import parsePhoneNumber from 'libphonenumber-js';

@Pipe({
    name: 'parsePhoneNumber',
})
export class ParsePhoneNumberPipe implements PipeTransform {
    transform(phoneNumber: string) {
        if (phoneNumber?.length) {
            try {
                return parsePhoneNumber(phoneNumber, 'SV').formatNational();
            } catch (error) {
                return phoneNumber;
            }
        }

        return phoneNumber;
    }
}
