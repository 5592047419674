import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { AwarePushService } from './aware-push.service';
import { AwarePushState } from './aware-push.state';

@NgModule({
    imports: [NgxsModule.forFeature([AwarePushState])],
    exports: [],
    providers: [AwarePushService],
})
export class AwarePushModule {}
