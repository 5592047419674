@if (size === 'large') {
  <div class="bg-light rounded-md flex flex-row gap-2 p-2 selected-border-color border-solid justify-between items-center"
    [ngClass]="selected ? 'border-2 p-[6px]' : ''"
    [ngClass]="button ? 'hover:cursor-pointer hover:border-2 hover:p-[6px]' : ''">
    <div class="flex flex-row gap-2 items-center">
      <div class="w-12 h-12 rounded-full bg-white flex justify-center items-center shrink-0">
        <ion-icon [name]="icon" class="text-primary text-2xl" [ngClass]="loading ? 'animate-spin' : ''"></ion-icon>
      </div>
      <div class="flex flex-col justify-center min-h-12">
        <p class="break-all" style="-ms-hyphens: auto; -moz-hyphens: auto; -webkit-hyphens: auto; hyphens: auto">{{ label
        | translate }}</p>
        <p class="text-xs line-clamp-2">{{ description | translate }}</p>
      </div>
    </div>
    @if (badge) {
      <div class="bg-primary rounded-full px-2 py-1 text-xs text-primary-contrast font-bold">{{ badgeLabel }}
      </div>
    }
    @if (dragHandle) {
      <div class="px-2 py-1">
        <ng-content></ng-content>
      </div>
    }
  </div>
}

<!-- SMALL -->
@if (size === 'small') {
  <div class="bg-light rounded-md flex flex-col gap-2 p-2 items-center relative w-full">
    <div class="rounded-full w-12 h-12 bg-white flex justify-center items-center text-xl flex-shrink-0">
      <ion-icon [name]="icon" class="text-primary" [ngClass]="{ 'animate-spin': loading }"></ion-icon>
    </div>
    <div class="flex flex-col justify-center max-w-full">
      <p class="overflow-ellipsis overflow-hidden whitespace-nowrap text-xs">{{ label | translate }}</p>
    </div>
  </div>
}