import { Injectable } from '@angular/core';

import { State, StateContext, Action, Selector, NgxsOnInit, Store } from '@ngxs/store';

import { HideTabs, SetPwaUpdateAvailable, ShowTabs, SubscribeToMyUnits } from './app.actions';
import { StateKey } from '@shared/enums';
import { UnitState } from './unit.state';
import { AuthState } from './auth.state';
import { AwarePushService } from '@shared/modules/push/aware-push.service';

export interface AppStateModel {
    showTabs: boolean;
    stateInitialized: boolean;
    pwaUpdateAvailable: boolean;
}

const defaults = {
    showTabs: true,
    stateInitialized: false,
    pwaUpdateAvailable: false,
};

@State<AppStateModel>({
    name: StateKey.App,
    defaults,
})
@Injectable({ providedIn: 'root' })
export class AppState implements NgxsOnInit {
    constructor(private store: Store, private awarePushService: AwarePushService) {}

    @Selector()
    static showTabs({ showTabs }: AppStateModel): boolean {
        return showTabs;
    }

    @Selector()
    static stateInitialized({ stateInitialized }: AppStateModel): boolean {
        return stateInitialized;
    }

    @Selector()
    static pwaUpdateAvailable({ pwaUpdateAvailable }: AppStateModel): boolean {
        return pwaUpdateAvailable;
    }

    ngxsOnInit({ dispatch, patchState }: StateContext<AppStateModel>) {
        patchState({ stateInitialized: true });
    }

    @Action(SubscribeToMyUnits)
    subscribeToMyUnits() {
        const { myUnits } = this.store.selectSnapshot(UnitState);
        const { client } = this.store.selectSnapshot(AuthState);

        if (client && myUnits?.length) {
            const topics = myUnits.map((u) => `unit_${u.uuid}`);

            this.awarePushService.subscribe(...topics);
        }
    }

    @Action(HideTabs)
    hideTabs({ patchState }: StateContext<AppStateModel>) {
        patchState({ showTabs: false });
    }

    @Action(ShowTabs)
    showTabs({ patchState }: StateContext<AppStateModel>) {
        patchState({ showTabs: true });
    }

    @Action(SetPwaUpdateAvailable)
    setPwaUpdateAvailable({ patchState }: StateContext<AppStateModel>, { available }: SetPwaUpdateAvailable) {
        patchState({ pwaUpdateAvailable: available });
    }
}
