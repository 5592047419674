import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
    selector: 'app-checkbox-card',
    templateUrl: 'checkbox-card.component.html',
    styleUrls: ['checkbox-card.component.scss'],
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps: [[new SkipSelf(), ControlContainer]],
        },
    ],
})
export class CheckboxCardComponent implements OnInit, OnDestroy {
    @ViewChild('checkbox') checkbox: ElementRef<HTMLInputElement>;

    @Input() text: string;
    @Input() controlName: string;
    @Input() checked: boolean;
    @Input() outputValue: any;
    @Input() color: 'white' | 'gray' = 'gray';
    @Input() size: 'small' | 'large' = 'large';
    @Input() disabled: boolean = false;

    @Output() valueChanged = new EventEmitter();

    constructor(private controlContainer: ControlContainer) {}

    ngOnInit() {}

    ngOnDestroy() {}

    toggle(event?) {
        if (this.disabled || this.controlContainer.control.get(this.controlName)?.disabled) return;

        const formControl = this.controlContainer.control.get(this.controlName);
        let newValue = !this.checkbox.nativeElement.checked;

        if (event) {
            event.stopPropagation();

            newValue = event.target.checked;
        } else {
            this.checkbox.nativeElement.checked = newValue;
        }

        if (formControl) {
            formControl.patchValue(newValue);
        } else {
            this.valueChanged.next(this.outputValue ? { value: this.outputValue, checked: newValue } : newValue);
        }
    }
}
