import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { GetUnitByUuidAndSetCurrentUnit, SetCurrentUnit, UnitState } from '@shared/state';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UnitGuard  {
    constructor(private store: Store, private navCtrl: NavController) {}

    canActivate(routeSnapshot: ActivatedRouteSnapshot) {
        const { unitUuid } = routeSnapshot.params;
        const { currentUnit } = this.store.selectSnapshot(UnitState);

        if (unitUuid === currentUnit?.uuid) {
            return true;
        } else {
            return this.store.dispatch(new GetUnitByUuidAndSetCurrentUnit(unitUuid)).pipe(
                catchError((err) => {
                    this.store.dispatch(new SetCurrentUnit(null));
                    this.navCtrl.navigateRoot('/public');

                    throw throwError(() => err);
                }),
                map(() => {
                    return true;
                }),
            );
        }
    }
}
