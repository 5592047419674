import { Store } from '@ngxs/store';

import { of } from 'rxjs';

import { AuthState, GetUnitInvites } from '@shared/state';

export function getMyInvites(store: Store) {
    const { client } = store.selectSnapshot(AuthState);

    if (client) {
        return store.dispatch(new GetUnitInvites());
    }

    return of(null);
}
