import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'sanitizeVideoUrl' })
export class SanitizeVideoUrlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(url: string): SafeResourceUrl {
        if (url.includes('youtu') && url.includes('watch?') && !url.includes('embed')) {
            url = url.replace('watch?v=', 'embed/');
        } else if (url.includes('vimeo') && !url.includes('player.vimeo')) {
            url = url.replace('vimeo.com', 'player.vimeo.com/video');
        }

        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
