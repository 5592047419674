import { Client, Navigation, Unit } from '@appbolaget/aware-model';

export function customStateDeserializer(data) {
    try {
        // if (typeof data !== 'string') {
        //     return data;
        // }

        const parsed = JSON.parse(data);

        if (parsed.organisation) {
            parsed.organisation = new Unit(parsed.organisation);

            try {
                parsed.organisation.config = JSON.parse(parsed.organisation.config);
            } catch (error) {}
        }

        if (parsed.currentUnit) {
            parsed.currentUnit = new Unit(parsed.currentUnit);
        }

        if (parsed.hierarchy) {
            parsed.hierarchy = parsed.hierarchy.map((unit) => new Unit(unit));
        }

        if (parsed.client) {
            parsed.client = new Client(parsed.client);
        }

        if (parsed.tabs) {
            parsed.tabs = new Navigation(parsed.tabs);
        }

        if (parsed.sidenav) {
            parsed.sidenav = new Navigation(parsed.sidenav);
        }

        return parsed;
    } catch (error) {
        return {};
    }
}
