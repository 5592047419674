import { Injectable } from '@angular/core';

import { TranslateLoader } from '@ngx-translate/core';

import { Store } from '@ngxs/store';

import { UnitState } from '@shared/state';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TranslationLoader implements TranslateLoader {
    constructor(public store: Store) {}

    getTranslation(lang: string) {
        return this.store.select(UnitState.appConfig).pipe(
            filter((appConfig) => !!appConfig),
            map((appConfig) => {
                const translations = appConfig.translations;

                return translations[lang];
            }),
        );
    }
}
