import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EditorConfig } from '@ckeditor/ckeditor5-core';
import { TranslateService } from '@ngx-translate/core';
import { skip, takeWhile } from 'rxjs';

@Component({
    selector: 'app-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EditorComponent),
            multi: true,
        },
    ],
})
export class EditorComponent implements OnInit, OnDestroy {
    @Input() placeholder = ' ';

    classicEditor = ClassicEditor;

    editorConfig: EditorConfig = {
        placeholder: this.translateService.instant(this.placeholder),
        toolbar: ['undo', 'redo', '|', 'heading', '|', 'bold', 'italic', '|', 'numberedList', 'bulletedList', '|', 'link'],
        link: {
            addTargetToExternalLinks: true,
        },
    };

    alive = true;

    editorControl = new FormControl();
    editorID: string = 'editor-' + Math.random().toString(36).substring(2, 9);

    touched = false;
    disabled = false;

    constructor(private translateService: TranslateService) {}

    ngOnInit(): void {
        this.editorControl.valueChanges
            .pipe(
                skip(1),
                takeWhile(() => this.alive),
            )
            .subscribe((value: any) => {
                this.onChange(value);
                this.onTouched();
            });
    }

    ngOnDestroy() {
        this.alive = false;
    }

    onChange = (value: string | number) => {};
    onTouched = () => {};

    writeValue(value: string | number) {
        this.editorControl.setValue(value);
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;

        if (disabled) {
            this.editorControl.disable();
        } else {
            this.editorControl.enable();
        }
    }
}
