import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'parseJson',
})
export class ParseJsonPipe implements PipeTransform {
    transform(jsonString: string): any {
        try {
            return JSON.parse(jsonString);
        } catch (error) {}
    }
}
