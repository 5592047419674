import { State, StateContext, Action, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { PushNotifications } from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';
import { AwareHttpRequestModule, AwareHttpService } from '@appbolaget/aware-http';

import { from } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import {
    DeleteTokenFromAware,
    InitializeFcm,
    RefreshFcmTopics,
    SubscribeToFcmTopics,
    SyncTokenWithAware,
    UnsubscribeFromFcmTopics,
} from './aware-push.actions';

export interface AwareFcmTopic {
    error: string;
    status: string;
    token: string;
    topic: string;
    uuid: string;
    id: string;
    updated_at: string;
    created_at: string;
    alias: string;
}

export interface PushStateModel {
    hasPermission: boolean;
    fcmToken: string;
    topics: AwareFcmTopic[];
}

const defaults = {
    hasPermission: false,
    fcmToken: null,
    topics: [],
};

@State<PushStateModel>({
    name: 'awarePush',
    defaults,
})
@Injectable({ providedIn: 'root' })
export class AwarePushState {
    constructor(private api: AwareHttpService) {}

    @Selector()
    static topics({ topics }: PushStateModel): AwareFcmTopic[] {
        return topics;
    }

    @Selector()
    static hasPermission({ hasPermission }: PushStateModel): boolean {
        return hasPermission;
    }

    @Selector()
    static fcmToken({ fcmToken }: PushStateModel): string {
        return fcmToken;
    }

    @Action(InitializeFcm)
    initializeFcm({ patchState }: StateContext<PushStateModel>) {
        return from(PushNotifications.requestPermissions()).pipe(
            tap((hasPermission) => {
                patchState({ hasPermission: hasPermission.receive === 'granted' });
            }),
            filter((hasPermission) => !!hasPermission),
            switchMap(() => from(PushNotifications.register())),
            switchMap(() => from(FCM.getToken()).pipe(tap(({ token }) => patchState({ fcmToken: token })))),
        );
    }

    @Action(SyncTokenWithAware)
    syncTokenWithAware({ getState }: StateContext<PushStateModel>) {
        const { fcmToken: token } = getState();

        return this.api.post('apps/token', { token }).module(AwareHttpRequestModule.Broadcast).execute();
    }

    @Action(DeleteTokenFromAware)
    deleteTokenFromAware({ getState }: StateContext<PushStateModel>) {
        const { fcmToken: token } = getState();

        return this.api.delete(`apps/token/${token}`, null).module(AwareHttpRequestModule.Broadcast).execute();
    }

    @Action(SubscribeToFcmTopics)
    subscribeToTopic({ patchState, getState }: StateContext<PushStateModel>, { topics }: SubscribeToFcmTopics) {
        const { fcmToken } = getState();

        return this.api
            .post('apps/topics', {
                token: fcmToken,
                topics,
            })
            .module(AwareHttpRequestModule.Broadcast)
            .execute()
            .pipe(
                tap((res) => {
                    const {
                        data: { updated },
                    } = res;
                    const { topics } = getState();
                    const filteredTopics = topics.filter((topic) => !updated.find((updatedTopic) => updatedTopic.topic === topic.topic));

                    patchState({
                        topics: [...filteredTopics, ...updated],
                    });
                }),
            );
    }

    @Action(UnsubscribeFromFcmTopics)
    unsubscribeFromTopics({ patchState, getState }: StateContext<PushStateModel>, { topics }: UnsubscribeFromFcmTopics) {
        const { fcmToken } = getState();

        return this.api
            .delete('apps/topics', {
                token: fcmToken,
                topics,
            })
            .module(AwareHttpRequestModule.Broadcast)
            .execute()
            .pipe(
                tap((res) => {
                    const {
                        data: { updated },
                    } = res;
                    const { topics } = getState();
                    const filteredTopics = topics.filter((topic) => !updated.find((updatedTopic) => updatedTopic.topic === topic.topic));

                    patchState({
                        topics: filteredTopics,
                    });
                }),
            );
    }

    @Action(RefreshFcmTopics)
    refreshFcmTopics({ patchState, getState }: StateContext<PushStateModel>) {
        const { fcmToken } = getState();

        return this.api
            .get('apps/topics')
            .parameter('app_token', fcmToken)
            .module(AwareHttpRequestModule.Broadcast)
            .execute()
            .pipe(tap((res) => patchState({ topics: res.data })));
    }
}
