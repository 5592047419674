<ion-select
    interface="popover"
    [label]="label | translate"
    [interfaceOptions]="interfaceOptions"
    [placeholder]="showInnerLabel ? (label | translate) : ''"
    [formControlName]="controlName"
    [cancelText]="cancelText | translate"
    [okText]="okText | translate"
    [compareWith]="compareWith"
    fill="solid"
    mode="md"
    class="my-0 py-0"
>
    <ng-content />
</ion-select>
