import { Component, ElementRef, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

import SwiperCore from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import { iconsConfig } from '@shared/configs';

SwiperCore.use([Navigation, Pagination]);

@Component({
    selector: 'app-icon-picker',
    templateUrl: 'icon-picker.component.html',
})
export class IconPickerComponent {
    @ViewChild('swiper') swiper: ElementRef | undefined;

    filled = iconsConfig.filled;
    outline = iconsConfig.outline;

    constructor(private modalCtrl: ModalController) {}

    selectIcon(icon: string) {
        this.modalCtrl.dismiss(icon);
    }

    close() {
        this.modalCtrl.dismiss();
    }

    segmentChanged(event) {
        const newIndex = Number(event.detail.value);

        this.swiper?.nativeElement.swiper.slideTo(newIndex);
    }
}
