import { Injectable } from '@angular/core';
import { Client } from '@appbolaget/aware-model';
import { Capacitor } from '@capacitor/core';
import { PushNotification, PushNotificationActionPerformed, PushNotifications } from '@capacitor/push-notifications';
import { Store } from '@ngxs/store';

import { of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
    DeleteTokenFromAware,
    InitializeFcm,
    RefreshFcmTopics,
    SubscribeToFcmTopics,
    SyncTokenWithAware,
    UnsubscribeFromFcmTopics,
} from './aware-push.actions';
import { AwarePushState } from './aware-push.state';

@Injectable()
export class AwarePushService {
    backgroundNotification$ = new Subject<PushNotification>();
    inAppNotification$ = new Subject<PushNotification>();

    constructor(private store: Store) {}

    initializeFcm() {
        if (Capacitor.isNativePlatform()) {
            return this.store.dispatch(new InitializeFcm()).pipe(
                tap(() => {
                    this.refreshFcmTopics();
                    this.listen();
                }),
            );
        }

        return of(null);
    }

    isSubscribedToTopic(topic: string) {
        const { topics } = this.store.selectSnapshot(AwarePushState);
        const found = topics.some((t) => t.topic === topic);

        return found;
    }

    subscribe(...topics: string[]) {
        if (Capacitor.isNativePlatform()) {
            return this.store.dispatch(new SubscribeToFcmTopics(topics));
        }

        return of(null);
    }

    unsubscribe(...topics: string[]) {
        if (Capacitor.isNativePlatform()) {
            return this.store.dispatch(new UnsubscribeFromFcmTopics(topics));
        }

        return of(null);
    }

    refreshFcmTopics() {
        if (Capacitor.isNativePlatform()) {
            return this.store.dispatch(new RefreshFcmTopics());
        }

        return of(null);
    }

    syncTokenWithAware(client: Client) {
        if (Capacitor.isNativePlatform() && client) {
            return this.store.dispatch(new SyncTokenWithAware());
        }

        return of(null);
    }

    deleteTokenFromAware(client: Client) {
        if (Capacitor.isNativePlatform() && client) {
            return this.store.dispatch(new DeleteTokenFromAware());
        }

        return of(null);
    }

    private listen() {
        /**
         * Push notification recieved while the app is open.
         */
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotification) => {
            this.inAppNotification$.next(notification);
        });

        /**
         * Push notification recieved while the app is in the background and the user tapped the notification.
         */
        PushNotifications.addListener('pushNotificationActionPerformed', (notification: PushNotificationActionPerformed) => {
            this.backgroundNotification$.next(notification.notification);
        });
    }
}
