import { Actions, ofActionCompleted, Store } from '@ngxs/store';

import { Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil, timeout } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthState, RefreshTokenAndClient } from '@shared/state';
import { add, isWithinInterval } from 'date-fns';

const jwtHelper = new JwtHelperService();

export function handleRefresh(store: Store, actions$: Actions, initted$: Subject<any>): Observable<any> {
    const authState = store.selectSnapshot(AuthState);

    if (authState.client && authState.token && authState.refreshToken) {
        return store.dispatch(new RefreshTokenAndClient()).pipe(
            timeout(15000),
            catchError((e) => {
                if (e.data?.type === 'auth_cooldown') {
                    const expDate = jwtHelper.getTokenExpirationDate(authState.token);

                    /**
                     *  Wait for a second refresh if the current token is expiring within the next two minutes,
                     *  otherwise we expect the refresh interval in auth.state to handle refreshing
                     */
                    if (isWithinInterval(expDate, { start: new Date(), end: add(new Date(), { minutes: 2 }) })) {
                        return actions$.pipe(
                            takeUntil(initted$),
                            ofActionCompleted(RefreshTokenAndClient),
                            map(() => {
                                return of(true);
                            }),
                        );
                    } else {
                        return of(null);
                    }
                }

                return of(null);
            }),
        );
    }

    return of(true);
}
