import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
    pure: false,
})
export class OrderByPipe implements PipeTransform {
    transform(arr: any[], key: string, direction: 'asc' | 'desc' = 'asc'): any[] {
        // Reassing the array so the items are no longer read only, this causes errors for some reason.
        if (arr) {
            arr = [...arr];
        } else {
            return [];
        }

        return arr.sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }

            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }

            return 0;
        });
    }
}
