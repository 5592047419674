export class HideTabs {
    static type = '[App] Hide tabs';
}

export class ShowTabs {
    static type = '[App] Show tabs';
}

export class SubscribeToMyUnits {
    static type = '[App] Subscribe to my units';
}

export class SetPwaUpdateAvailable {
    static type = '[App] Set PWA update available';

    constructor(public available: boolean) {}
}
