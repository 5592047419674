<ion-header>
  <ion-toolbar>
    @if (file$ | async; as file) {
      <ion-title>{{ file.title }}</ion-title>
    }

    <ion-buttons slot="end">
      @if (fileIsViewable) {
        <ion-button (click)="downloadFile()">
          <ion-icon name="cloud-download-outline"></ion-icon>
        </ion-button>
      }

      <ion-button (click)="close()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  @if (!LOADING) {
    @if (fileIsViewable) {
      @if (file$ | async; as file) {
        <div class="p-2 h-full">
          @if (file.isImage()) {
            <div class="w-full h-full flex justify-center items-center">
              <img [src]="file.uuid | imageByUuid" class="rounded-md max-h-full max-w-full" />
            </div>
          }
          @if (isPdf()) {
            <pdf-viewer
              [src]="getAwareUrl()"
              [render-text]="true"
              [original-size]="false"
              style="width: 100%; height: 100%"
            ></pdf-viewer>
          }
          @if (!file.isImage() && !isPdf()) {
            <iframe class="w-full h-full" [src]="fileUrl"></iframe>
          }
        </div>
      }
    } @else {
      @if (!DOWNLOADING) {
        <div class="flex gap-2 items-center justify-center flex-col text-center text-[#828282] h-full">
          <p>Den här filen kan inte visas.</p>
          <ion-button shape="round" color="primary" (click)="downloadFile()">Ladda ned</ion-button>
        </div>
      } @else {
        <div
    class="
      py-5
      flex
      gap-2
      border-gray-500 border-solid border-b border-opacity-30
      last:border-0
      outline-none
      items-center
      justify-center
      text-center text-[#828282]
      h-full
    "
          >
          <ion-icon name="sync-outline" class="animate-spin"></ion-icon>
          Laddar ned...
        </div>
      }
    }
  } @else {
    <div
    class="
      py-5
      flex
      gap-2
      border-gray-500 border-solid border-b border-opacity-30
      last:border-0
      outline-none
      items-center
      justify-center
      text-center text-[#828282]
      h-full
    "
      >
      <ion-icon name="sync-outline" class="animate-spin"></ion-icon>
      Laddar...
    </div>
  }
</ion-content>



