<form class="bg-light rounded-md h-9 flex flex-row items-center">
  <div class="pl-2 pr-2 inline-flex justify-center items-center h-full">
    @if (LOADING) {
      <ion-icon aria-hidden="true" name="sync-outline" class="animate-spin"></ion-icon>
    }
    @if (!LOADING) {
      <ion-icon aria-hidden="true" [name]="icon"></ion-icon>
    }
  </div>

  <input #searchInput class="h-full w-full bg-transparent outline-none" [placeholder]="placeholder | translate"
    [formControl]="searchControl" />
</form>