import { Directive, HostListener, Input } from '@angular/core';
import { NavController } from '@ionic/angular';

import { Navigation } from '@appbolaget/aware-model';
import { Store } from '@ngxs/store';
import { UnitState } from '@shared/state';

@Directive({ selector: '[navigationClick]' })
export class NavigationClickDirective {
    @Input() navigationClick: Navigation;

    constructor(private navCtrl: NavController, private store: Store) {}

    @HostListener('click')
    onClick() {
        const type = this.navigationClick.getMeta('type');

        switch (type) {
            case 'navigation':
                this.handleNavigationType();
                break;

            case 'url':
                this.handleUrlType();
                break;

            case 'connection':
                this.handleConnectionType();
                break;
        }
    }

    private handleNavigationType() {
        const currentUnit = this.store.selectSnapshot(UnitState.currentUnit);

        this.navCtrl.navigateForward(['/', currentUnit.uuid, 'navigation', this.navigationClick.uuid]);
    }

    private handleUrlType() {
        const url = this.navigationClick.getMeta('url');

        window.open(url, '_blank');
    }

    private handleConnectionType() {
        const currentUnit = this.store.selectSnapshot(UnitState.currentUnit);
        const { post } = this.navigationClick;

        if (post.type === 'approute') {
            this.navCtrl.navigateForward(['/', currentUnit.uuid, 'components', post.getMeta('route')]);
        } else {
            this.navCtrl.navigateForward(['/', currentUnit.uuid, 'post', post.uuid]);
        }
    }
}
