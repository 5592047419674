import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';

import { Observable, Subject } from 'rxjs';

import { Client, Navigation, Unit } from '@appbolaget/aware-model';
import { PressGesture } from '@appbolaget/gestures';

import { AppState, AuthState, RefreshTokenAndClient, SetPwaUpdateAvailable, SubscribeToMyUnits, UnitState } from '@shared/state';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { AwarePushService } from '@shared/modules/push/aware-push.service';
import { ToastService } from '@shared/services';
import { NavController, Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { TranslateService } from '@ngx-translate/core';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    @Select(AppState.pwaUpdateAvailable) pwaUpdateAvailable$: Observable<boolean>;
    @Select(AuthState.client) client$: Observable<Client>;
    @Select(AuthState.unitInvitesAmount) unitInvitesAmount$: Observable<number>;
    @Select(UnitState.currentUnitStartPageRoute) currentUnitStartPageRoute$: Observable<string>;
    @Select(UnitState.currentUnitBaseRoute) currentUnitBaseRoute$: Observable<string>;
    @Select(UnitState.currentUnit) currentUnit$: Observable<Unit>;
    @Select(UnitState.masterUnit) masterUnit$: Observable<Unit>;
    @Select(UnitState.sidenav) sidenav$: Observable<Navigation>;

    splitPaneVisible: boolean;

    reloadGesture = new PressGesture().time(4000).callback(() => window.location.reload());

    constructor(
        private swUpdate: SwUpdate,
        private awarePushService: AwarePushService,
        private store: Store,
        private toastService: ToastService,
        private navCtrl: NavController,
        private platform: Platform,
        private translate: TranslateService,
    ) {}

    async ngOnInit() {
        if (Capacitor.isNativePlatform()) {
            SplashScreen.hide();

            this.initializeNative();
        } else {
            this.initializeNonNative();
        }
    }

    splitPaneVisibleChanged(event) {
        this.splitPaneVisible = event.detail.visible;
    }

    reload() {
        window.location.reload();
    }

    private initializeNative() {
        const { client } = this.store.selectSnapshot(AuthState);

        this.platform.resume
            .pipe(
                tap(() => {
                    this.store.dispatch(new RefreshTokenAndClient());
                }),
            )
            .subscribe();

        StatusBar.setBackgroundColor({ color: 'ffffff' });
        StatusBar.setStyle({ style: Style.Light });

        this.awarePushService
            .initializeFcm()
            .pipe(
                switchMap(() => this.awarePushService.syncTokenWithAware(client)),
                tap(() => {
                    this.store.dispatch(new SubscribeToMyUnits());

                    this.listenToPush();
                }),
            )
            .subscribe();
    }

    private listenToPush() {
        this.awarePushService.backgroundNotification$
            .pipe(
                tap((notification) => {
                    const { data } = notification;
                    const { action, action_id } = data;
                    const { currentUnit } = this.store.selectSnapshot(UnitState);

                    switch (action) {
                        case 'news':
                            this.navCtrl.navigateForward(`${currentUnit.uuid}/modules/tabs/news/${action_id}`);
                            break;
                    }
                }),
            )
            .subscribe();

        this.awarePushService.inAppNotification$
            .pipe(
                tap((notification) => {
                    const { data } = notification;
                    const { action, body, action_id } = data;
                    const { currentUnit } = this.store.selectSnapshot(UnitState);

                    switch (action) {
                        case 'news':
                            this.toastService.info({
                                message: body,
                                duration: 5000,
                                buttons: [
                                    {
                                        text: this.translate.instant('COMMON.SHOW'),
                                        handler: () => this.navCtrl.navigateForward(`${currentUnit.uuid}/modules/tabs/news/${action_id}`),
                                    },
                                    {
                                        icon: 'close',
                                    },
                                ],
                            });
                            break;
                    }
                }),
            )
            .subscribe();
    }

    private async initializeNonNative() {
        const listenForUpdates = new Subject<void>();

        document.addEventListener('visibilitychange', (ev) => {
            if (document.visibilityState === 'visible') {
                this.store.dispatch(new RefreshTokenAndClient());
            }
        });

        this.swUpdate.versionUpdates
            .pipe(
                takeUntil(listenForUpdates),
                filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
                tap(() => {
                    window.location.reload();
                }),
            )
            .subscribe();

        if (this.swUpdate.isEnabled) {
            await this.swUpdate.checkForUpdate();

            setTimeout(() => {
                listenForUpdates.next();

                this.listenForUpdatesContinuously();
            }, 300);
        }
    }

    private listenForUpdatesContinuously() {
        this.swUpdate.versionUpdates
            .pipe(
                filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
                tap(() => {
                    this.store.dispatch(new SetPwaUpdateAvailable(true));
                }),
            )
            .subscribe();

        setInterval(async () => {
            await this.swUpdate.checkForUpdate();
        }, 30000);
    }
}
