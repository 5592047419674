import { serializeAwareModel } from '@appbolaget/aware-model';

const props = ['currentUnit', 'client', 'tabs', 'sidenav', 'organisation'];

export function customStateSerializer(data) {
    let serializedData = data;

    for (const prop of props) {
        if (data[prop]) {
            serializedData = {
                ...serializedData,
                [prop]: serializeAwareModel(data[prop]),
            };
        }
    }

    if (data['hierarchy']) {
        serializedData = {
            ...serializedData,
            hierarchy: data['hierarchy'].map((unit) => serializeAwareModel(unit)),
        };
    }

    return JSON.stringify(serializedData);
}
