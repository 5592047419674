import { Injectable } from '@angular/core';

import { State, StateContext, Action, Selector, Store, NgxsOnInit } from '@ngxs/store';

import { tap, catchError, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';

// import { isAfter, sub } from 'date-fns';

// import { JwtHelperService } from '@auth0/angular-jwt';

import { AwareHttpService } from '@appbolaget/aware-http';
import { Client, Unit } from '@appbolaget/aware-model';

import { Login, LoginUnsuccessful, LoginSuccessful, Logout, RefreshTokenAndClient, SetToken } from './auth.actions';
import { StateKey } from '@shared/enums';
import { UnitState } from '@shared/state/unit.state';
import { GetOrganisation, SetCurrentUnit } from '@shared/state/unit.actions';
import { GetUnitInvites } from '.';
import { environment } from '@env';
import { AwarePushService } from '@shared/modules/push/aware-push.service';

// const jwtHelper = new JwtHelperService();

export interface AuthStateModel {
    client: Client;
    token: string;
    unitInvites: Unit[];
    refreshToken: string;
}

const defaults: AuthStateModel = {
    client: null,
    token: null,
    unitInvites: null,
    refreshToken: null,
};

@State<AuthStateModel>({
    name: StateKey.Auth,
    defaults,
})
@Injectable({ providedIn: 'root' })
export class AuthState implements NgxsOnInit {
    constructor(private api: AwareHttpService, private store: Store, private awarePushService: AwarePushService) {}

    @Selector()
    static client({ client }: AuthStateModel): Client {
        return client;
    }

    @Selector()
    static token({ token }: AuthStateModel): string {
        return token;
    }

    @Selector()
    static refreshToken({ refreshToken }: AuthStateModel): string {
        return refreshToken;
    }

    @Selector()
    static unitInvites({ unitInvites }: AuthStateModel): Unit[] {
        return unitInvites;
    }

    @Selector()
    static unitInvitesAmount({ unitInvites }: AuthStateModel): number {
        if (!unitInvites) {
            return 0;
        }

        return unitInvites.length;
    }

    ngxsOnInit({ getState }: StateContext<AuthStateModel>) {
        const { token } = getState();

        this.api.setToken(token);
    }

    @Action(GetUnitInvites)
    getUnitInvites({ patchState }: StateContext<AuthStateModel>) {
        return this.api
            .get('grupper/units')
            .parameter('type', 'invite')
            .unit(environment.api.baseUnit)
            .toCollection(Unit)
            .execute()
            .pipe(tap((res) => patchState({ unitInvites: res.data })));
    }

    @Action(Login)
    login({ patchState, dispatch }: StateContext<AuthStateModel>, { credentials }: Login) {
        return this.api
            .post('authenticate', {
                ...credentials,
                type: 'email',
                no_ip_validation: true,
                remember: 600000000,
            })
            .expand(['units', 'meta', 'communications'])
            .execute()
            .pipe(
                catchError((err) => {
                    dispatch(new LoginUnsuccessful());

                    return throwError(() => err);
                }),
                tap((result) => {
                    const { client, refresh_token, token } = result.data;

                    dispatch([new LoginSuccessful(), new SetToken(token), new GetUnitInvites()]);

                    this.awarePushService.syncTokenWithAware(client);

                    patchState({
                        client: new Client(client),
                        refreshToken: refresh_token,
                    });
                }),
                switchMap(() => dispatch(new GetOrganisation())),
            );
    }

    @Action(Logout)
    logout({ patchState, dispatch, getState }: StateContext<AuthStateModel>) {
        const { client } = getState();

        this.awarePushService
            .deleteTokenFromAware(client)
            .pipe(
                tap(() => {
                    const currentUnit = this.store.selectSnapshot(UnitState.currentUnit);

                    if (!currentUnit?.public) {
                        this.store.dispatch(new SetCurrentUnit(null));
                    }

                    // patchState(defaults);

                    dispatch(new SetToken(null));
                }),
            )
            .subscribe();

        patchState(defaults);
    }

    @Action(RefreshTokenAndClient)
    refreshTokenAndClient({ dispatch, getState, patchState }: StateContext<AuthStateModel>) {
        const { refreshToken } = getState();

        return this.api
            .post('authenticate/refresh', { refresh_token: refreshToken, no_ip_validation: true })
            .token(null)
            .execute()
            .pipe(
                tap((result) => {
                    const { token } = result.data;

                    dispatch(new SetToken(token));

                    patchState({ client: new Client(result.data.client) });
                }),
                catchError((error) => {
                    if (error?.data?.type === 'auth_cooldown') {
                        setTimeout(() => {
                            dispatch(new RefreshTokenAndClient());
                        }, 5000);
                    } else {
                        dispatch(new Logout());

                        patchState({ token: null, refreshToken: null });
                    }

                    return throwError(() => error);
                }),
            );
    }

    @Action(SetToken)
    setToken({ patchState }: StateContext<AuthStateModel>, { token }: SetToken) {
        this.api.setToken(token);

        patchState({ token });
    }
}
