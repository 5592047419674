import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { skip, takeWhile } from 'rxjs';

@Component({
    selector: 'app-input',
    templateUrl: 'input.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() placeholder: string;
    @Input() type: string = 'text';
    @Input() label: string;

    alive = true;

    inputControl = new FormControl();
    inputID: string = 'input-' + Math.random().toString(36).substring(2, 9);

    touched = false;
    disabled = false;

    ngOnInit(): void {
        this.inputControl.valueChanges
            .pipe(
                skip(1),
                takeWhile(() => this.alive),
            )
            .subscribe((value: any) => {
                this.onChange(value);
                this.onTouched();
            });
    }

    ngOnDestroy() {
        this.alive = false;
    }

    onChange = (value: string | number) => {};
    onTouched = () => {};

    writeValue(value: string | number) {
        this.inputControl.setValue(value);
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;

        if (disabled) {
            this.inputControl.disable();
        } else {
            this.inputControl.enable();
        }
    }
}
