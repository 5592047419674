import { Component, ElementRef, Input, OnDestroy, OnInit, SkipSelf, ViewChild } from '@angular/core';
import { ControlContainer } from '@angular/forms';

import { takeWhile, tap } from 'rxjs/operators';

@Component({
    selector: 'app-textarea',
    templateUrl: 'textarea.component.html',
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps: [[new SkipSelf(), ControlContainer]],
        },
    ],
})
export class TextareaComponent implements OnInit, OnDestroy {
    @ViewChild('textarea') textarea: ElementRef;

    @Input() placeholder: string;
    @Input() label: string;
    @Input() controlName: string;
    @Input() errorAware: boolean = true;
    @Input() minHeight = 68;
    @Input() maxHeight = null;
    @Input() autoGrow = true;

    private initialValue: string;

    HAS_ERRORS: boolean;
    ALIVE = true;

    constructor(private controlContainer: ControlContainer) {}

    ngOnInit() {
        if (this.errorAware && this.controlName) {
            const formControl = this.controlContainer.control.get(this.controlName);

            this.initialValue = formControl.value;

            formControl.valueChanges
                .pipe(
                    takeWhile(() => this.ALIVE),
                    tap((value) => {
                        if (formControl.errors) {
                            const errorKeys = Object.keys(formControl.errors);

                            if (errorKeys.length && value !== this.initialValue) {
                                this.HAS_ERRORS = true;
                            } else {
                                this.HAS_ERRORS = false;
                            }
                        } else {
                            this.HAS_ERRORS = false;
                        }
                    }),
                )
                .subscribe();
        }

        if (this.autoGrow && this.controlName) {
            this.detectChanges();
        }

        if (this.autoGrow) {
            setTimeout(() => {
                this.resize();
            }, 30);
        }
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    private detectChanges() {
        const formControl = this.controlContainer.control.get(this.controlName);

        formControl.valueChanges
            .pipe(
                takeWhile(() => this.ALIVE),
                tap(() => {
                    this.resize();
                }),
            )
            .subscribe();
    }

    private resize() {
        if (this.textarea) {
            const { nativeElement } = this.textarea;

            nativeElement.style.height = 'auto';

            let height = nativeElement.scrollHeight;

            if (height < this.minHeight) {
                height = this.minHeight;
            } else if (this.maxHeight && height > this.maxHeight) {
                height = this.maxHeight;
                nativeElement.style.overflow = 'scroll';
            } else {
                nativeElement.style.overflow = 'hidden';
            }

            nativeElement.style.height = `${height}px`;
        }
    }
}
