import { Injectable } from '@angular/core';
import { File as AwareFile } from '@appbolaget/aware-model';
import { Capacitor } from '@capacitor/core';
import { ModalController } from '@ionic/angular';
import { FileViewerComponent } from '@shared/components/file-viewer/file-viewer.component';

@Injectable({ providedIn: 'root' })
export class FileService {
    constructor(private modalCtrl: ModalController) {}

    async getFiles(multiple: boolean, options?: GetFilesOptions): Promise<FormData> {
        if (Capacitor.isNativePlatform()) {
            return await this.getFilesNonNative(multiple, '*', options);
        } else {
            return await this.getFilesNonNative(multiple, '*', options);
        }
    }

    async getImages(multiple: boolean, options?: GetFilesOptions): Promise<FormData> {
        if (Capacitor.isNativePlatform()) {
            return await this.getFilesNonNative(multiple, 'image/*', options);
        } else {
            return await this.getFilesNonNative(multiple, 'image/*', options);
        }
    }

    async viewFile(file: AwareFile) {
        (
            await this.modalCtrl.create({
                component: FileViewerComponent,
                componentProps: { fileUuid: file.uuid },
                cssClass: 'file-viewer-modal',
            })
        ).present();
    }

    private getFilesNonNative(multiple: boolean, accept: 'image/*' | '*', options: GetFilesOptions): Promise<FormData> {
        return new Promise((resolve) => {
            let input = document.createElement('input');

            input.type = 'file';
            input.multiple = multiple;
            input.accept = accept;

            input.onchange = (event) => {
                const files = Array.from(input.files);

                resolve(this.createFormData(files, options));

                document.body.removeChild(input);
            };

            input.style.position = 'absolute';
            input.style.right = '-9999px';
            input.style.top = '-9999px';

            document.body.appendChild(input);

            input.click();
        });
    }

    private createFormData(files: File[], options: GetFilesOptions = {}): FormData {
        const formData = new FormData();

        if (options.private) {
            formData.append('private', 'true');
        }

        if (options.public) {
            formData.append('public', 'true');
        }

        for (const file of files) {
            formData.append('files[]', file, file.name);
        }

        return formData;
    }
}

export interface SelectedFile {
    name: string;
    base64: string;
}

export interface GetFilesOptions {
    private?: boolean;
    public?: boolean;
}
