import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-icon',
    templateUrl: 'icon.component.html',
})
export class IconComponent {
    @Input() label: string;
    @Input() icon: string;
    @Input() size: 'small' | 'large' = 'small';
    @Input() button = false;

    constructor() {}
}
