import { Component, Input, OnDestroy, OnInit, SkipSelf } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { takeWhile, tap } from 'rxjs/operators';

@Component({
    selector: 'app-select',
    templateUrl: 'select.component.html',
    viewProviders: [
        {
            provide: ControlContainer,
            useFactory: (container: ControlContainer) => container,
            deps: [[new SkipSelf(), ControlContainer]],
        },
    ],
})
export class SelectComponent implements OnInit, OnDestroy {
    @Input() label: string;
    @Input() controlName: string;
    @Input() errorAware: boolean = true;
    @Input() color: 'light' | 'default' = 'default';
    @Input() cancelText: string = 'Avbryt';
    @Input() okText: string = 'Ok';
    @Input() compareWith: string | ((o1: any, o2: any) => boolean);
    @Input() showInnerLabel = true;

    interfaceOptions: any;

    private initialValue: string;

    HAS_ERRORS: boolean;
    ALIVE = true;

    constructor(private controlContainer: ControlContainer, private translate: TranslateService) {}

    ngOnInit() {
        this.interfaceOptions = {
            header: this.translate.instant(this.label),
        };

        if (this.errorAware) {
            const formControl = this.controlContainer.control.get(this.controlName);

            this.initialValue = formControl.value;

            formControl.valueChanges
                .pipe(
                    takeWhile(() => this.ALIVE),
                    tap((value) => {
                        if (formControl.errors) {
                            const errorKeys = Object.keys(formControl.errors);

                            if (errorKeys.length && value !== this.initialValue) {
                                this.HAS_ERRORS = true;
                            } else {
                                this.HAS_ERRORS = false;
                            }
                        } else {
                            this.HAS_ERRORS = false;
                        }
                    }),
                )
                .subscribe();
        }
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }
}
