import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { IonicModule } from '@ionic/angular';

import { DateFnsModule } from 'ngx-date-fns';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { ImageByUuidPipe, LinkifyPipe, OrderByPipe, ParseJsonPipe, ParsePhoneNumberPipe, StripHtmlPipe, ToDatePipe } from '@shared/pipes';

import {
    BackButtonComponent,
    CheckboxCardComponent,
    ClientAvatarComponent,
    ClientCardComponent,
    ClientProfileComponent,
    ClientProfileHeaderComponent,
    FileViewerComponent,
    IconCardComponent,
    IconComponent,
    IconPickerComponent,
    IndeterminateLoaderComponent,
    InputComponent,
    LanguageSelectComponent,
    LoaderComponent,
    MediaLibraryComponent,
    SearchInputComponent,
    SelectComponent,
    TextareaComponent,
    UnitCardComponent,
} from './components';
import { NavigationClickDirective, ShouldRenderNavigationDirective } from './directives';
import { AwareSecurityModule } from '@appbolaget/aware-security';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { SanitizeVideoUrlPipe } from '@public/views/e-learning/sanitize-video-url.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EditorComponent } from './components/editor/editor.component';
import { HtmlContentComponent } from './components/html-content/html-content.component';

const modules = [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DateFnsModule,
    AwareSecurityModule,
    MatChipsModule,
    MatFormFieldModule,
    PdfViewerModule,
    TranslateModule,
    DragDropModule,
    CKEditorModule,
];
const components = [
    BackButtonComponent,
    CheckboxCardComponent,
    ClientAvatarComponent,
    ClientCardComponent,
    ClientProfileComponent,
    ClientProfileHeaderComponent,
    FileViewerComponent,
    IconCardComponent,
    IconPickerComponent,
    IconComponent,
    IndeterminateLoaderComponent,
    InputComponent,
    LanguageSelectComponent,
    LoaderComponent,
    MediaLibraryComponent,
    SearchInputComponent,
    SelectComponent,
    TextareaComponent,
    UnitCardComponent,
    EditorComponent,
    HtmlContentComponent,
];
const pipes = [
    ImageByUuidPipe,
    LinkifyPipe,
    OrderByPipe,
    ParseJsonPipe,
    ParsePhoneNumberPipe,
    StripHtmlPipe,
    ToDatePipe,
    SanitizeVideoUrlPipe,
];
const directives = [NavigationClickDirective, ShouldRenderNavigationDirective];

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [...modules],
    exports: [...pipes, ...components, ...modules, ...directives],
    declarations: [...pipes, ...components, ...directives],
})
export class SharedModule {}
