import { NavController } from '@ionic/angular';

import { Store } from '@ngxs/store';

import { AwareHttpService } from '@appbolaget/aware-http';

import { of, Subject } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { AuthState, UnitState, UnitStateModel } from '@shared/state';

const redirectableRoutes = ['/settings/my-units-settings', '/finalize-invite'];

export function routeClient(store: Store, api: AwareHttpService, navCtrl: NavController, initted$: Subject<void>) {
    const unitState: UnitStateModel = store.selectSnapshot(UnitState);
    const { currentUnit, currentUnitStartPageRoute, currentUnitModule } = unitState;
    const { client } = store.selectSnapshot(AuthState);

    if (currentUnit) {
        api.setUnit(currentUnit.uuid);

        if (currentUnitModule === 'tabs') {
            return store.select(UnitState.tabs).pipe(
                filter((tabs) => !!tabs),
                take(1),
                tap((tabs) => {
                    /**
                     * If the user is navigating to a route that isn't the base route let them,
                     * otherwise redirect them to the first tab.
                     */
                    const queryParams = {};
                    new URLSearchParams(window.location.search).forEach((value, key) => {
                        queryParams[key] = value;
                    });

                    if (window.location.pathname && window.location.pathname !== '/') {
                        navCtrl.navigateRoot(window.location.pathname, { replaceUrl: true, queryParams });
                    } else {
                        navCtrl.navigateRoot(currentUnitStartPageRoute, { replaceUrl: true });
                    }

                    initted$.next();
                }),
            );
        } else {
            /**
             * If the units module isn't tabs, navigate to the start page route.
             */
            navCtrl.navigateRoot(currentUnitStartPageRoute);
        }
    } else if (client && !isRedirectable(window.location.pathname)) {
        navCtrl.navigateRoot('/public/my-units');
    } else {
        if (window.location.pathname && isRedirectable(window.location.pathname)) {
            if (!client) {
                navCtrl.navigateRoot('/auth', {
                    queryParams: {
                        redirect: window.location.pathname,
                    },
                });
            }
        } else if (window.location.pathname.includes('auth/restore-password')) {
            // This elif statement is just so we dont go the else, because this route is allowed.
        } else {
            navCtrl.navigateRoot('/public');
        }
    }

    initted$.next();

    return of(true);
}

function isRedirectable(route: string) {
    for (const redirectableRoute of redirectableRoutes) {
        if (route.includes(redirectableRoute)) {
            return true;
        }
    }

    return false;
}
