import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    ActionSheetController,
    AlertController,
    LoadingController,
    ActionSheetButton,
    ModalController,
    // IonRouterOutlet,
} from '@ionic/angular';
import { Capacitor } from '@capacitor/core';

import { Clipboard } from '@capacitor/clipboard';

import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
// import { v4 } from 'uuid';

import { AwareHttpRequestModule, AwareHttpService } from '@appbolaget/aware-http';
import { Bucket, File, Unit } from '@appbolaget/aware-model';
import { FileService, ToastService } from '@shared/services';
import { environment } from '@env';
import { Select } from '@ngxs/store';
import { UnitState } from '@shared/state';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-media-library',
    templateUrl: 'media-library.component.html',
    styleUrls: ['media-library.component.scss'],
})
export class MediaLibraryComponent implements OnInit, OnDestroy {
    @Select(UnitState.currentUnit) currentUnit$: Observable<Unit>;

    @ViewChild('stackInner') stackInner;
    // @ViewChild(IonRouterOutlet, { static: true }) ionRouterOutlet: IonRouterOutlet;

    @Input() menuButton: boolean;
    @Input() closeButton: boolean;
    @Input() allowSelect: boolean;
    @Input() maxSelectAmount: number;
    @Input() selectedFiles: File[] = [];
    @Input() page = false;

    fileTree: any;
    currentBucket: Bucket;
    stack: Bucket[] = [];

    canGoBack: boolean;

    UPLOADING_FILES = false;
    ALIVE = true;
    LOADING = false;

    constructor(
        private api: AwareHttpService,
        private toastService: ToastService,
        private actionSheetCtrl: ActionSheetController,
        private alertCtrl: AlertController,
        private loadingCtrl: LoadingController,
        private fileService: FileService,
        private modalCtrl: ModalController,
        private translate: TranslateService, // private ionRouterOutlet: IonRouterOutlet,
    ) {}

    ngOnInit() {
        // this.canGoBack = this.ionRouterOutlet.canGoBack();

        this.getMedia().subscribe();
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    closeModal(output = false) {
        this.modalCtrl.dismiss(
            output
                ? {
                      selectedFiles: this.selectedFiles,
                  }
                : null,
        );
    }

    fileClicked(file: File) {
        if (this.allowSelect) {
            const isSelected = this.selectedFiles.some((f) => f.uuid === file.uuid);

            if (this.selectedFiles.length === this.maxSelectAmount && !isSelected) {
                this.toastService.info(
                    `${this.translate.instant('PAGES.MEDIA.CANT_SELECT_MORE_THAN')} ${this.maxSelectAmount} ${this.translate
                        .instant('PAGES.MEDIA.FILES')
                        .toLowerCase()}.`,
                );
            }

            if (this.maxSelectAmount === 1) {
                this.toggleFileSelected(file);
                this.closeModal(true);
            } else if (!this.maxSelectAmount || this.selectedFiles.length < this.maxSelectAmount || isSelected) {
                this.toggleFileSelected(file);
            }
        } else {
            this.previewFile(file);
        }
    }

    async showAddActionSheet() {
        const buttons: ActionSheetButton[] = [];

        // if (Capacitor.isNativePlatform()) {
        buttons.push({
            text: this.translate.instant('PAGES.MEDIA.UPLOAD_FILES_IMAGES'),
            icon: 'document-outline',
            handler: () => this.uploadFiles(),
        });
        // }

        if (Capacitor.isNativePlatform()) {
            // buttons.push({ text: 'Ladda upp från kamera', icon: 'camera-outline', handler: () => {} });
        }

        buttons.push(
            { text: this.translate.instant('PAGES.MEDIA.NEW_FOLDER'), icon: 'folder-outline', handler: () => this.showNewFolderPrompt() },
            { text: this.translate.instant('COMMON.CANCEL'), icon: 'close' },
        );

        (
            await this.actionSheetCtrl.create({
                header: this.translate.instant('COMMON.CREATE'),
                buttons,
            })
        ).present();
    }

    goToBucket(bucket: Bucket, clean?: boolean) {
        this.getMedia(bucket).subscribe();

        this.currentBucket = bucket;
        this.stack.push(bucket);

        this.scrollStack();

        if (clean) {
            this.cleanStack(bucket);
        }
    }

    popStack() {
        this.stack.pop();

        const stackLength = this.stack.length;

        let newBucket;

        if (stackLength) {
            newBucket = this.stack[stackLength - 1];
        } else {
            newBucket = this.fileTree;
        }

        this.currentBucket = newBucket;

        this.getMedia(this.currentBucket).subscribe();
    }

    itemIsntLastInStack(item: Bucket): boolean {
        const index = this.stack.indexOf(item);
        const length = this.stack.length;

        if (index === length - 1) {
            return false;
        }

        return true;
    }

    async showBucketActions(bucket: Bucket, event?) {
        if (event) event.stopPropagation();

        (
            await this.actionSheetCtrl.create({
                header: bucket.title,
                buttons: [
                    {
                        text: this.translate.instant('PAGES.MEDIA.RENAME'),
                        icon: 'create',
                        handler: () => this.showChangeFileOrBucketNamePrompt(bucket),
                    },
                    {
                        text: this.translate.instant('COMMON.DELETE'),
                        icon: 'trash',
                        handler: () => this.showDeleteFileOrBucketPrompt(bucket),
                    },
                    { text: this.translate.instant('COMMON.CANCEL'), icon: 'close' },
                ],
            })
        ).present();
    }

    async showFileActions(file: File, event?) {
        if (event) event.stopPropagation();

        (
            await this.actionSheetCtrl.create({
                header: file.title,
                buttons: [
                    {
                        text: this.translate.instant('PAGES.MEDIA.RENAME'),
                        icon: 'create-outline',
                        handler: () => this.showChangeFileOrBucketNamePrompt(file),
                    },
                    { text: this.translate.instant('PAGES.MEDIA.COPY_URL'), icon: 'copy-outline', handler: () => this.copyFileUrl(file) },
                    {
                        text: this.translate.instant('COMMON.DELETE'),
                        icon: 'trash-outline',
                        handler: () => this.showDeleteFileOrBucketPrompt(file),
                    },
                    { text: this.translate.instant('COMMON.CANCEL'), icon: 'close' },
                ],
            })
        ).present();
    }

    trackByUuid(index: number, fileOrBucket: File | Bucket): string {
        return fileOrBucket.uuid;
    }

    isFileSelected(file: File) {
        return this.selectedFiles.some((f) => f.uuid === file.uuid);
    }

    private previewFile(file: File) {
        this.fileService.viewFile(file);
    }

    private toggleFileSelected(file: File) {
        const isSelected = this.selectedFiles.some((f) => f.uuid === file.uuid);

        if (isSelected) {
            this.selectedFiles = this.selectedFiles.filter((f) => f.uuid !== file.uuid);
        } else {
            this.selectedFiles.push(file);
        }
    }

    private async uploadFiles() {
        const formData = await this.fileService.getFiles(true);

        if (!formData) return;

        this.UPLOADING_FILES = true;

        if (this.currentBucket?.uuid) {
            formData.append('bucket', this.currentBucket.uuid);
        }

        this.api
            .upload(formData, 'files')
            .execute()
            .pipe(
                catchError((err) => {
                    this.toastService.error('ERRORS.SOMETHING_WENT_WRONG');
                    this.UPLOADING_FILES = false;
                    throw throwError(() => err);
                }),
                switchMap(() => this.getMedia(this.currentBucket)),
                tap(() => {
                    this.toastService.success('PAGES.MEDIA.UPLOAD_COMPLETE');
                    this.UPLOADING_FILES = false;
                }),
            )
            .subscribe();
    }

    private async showNewFolderPrompt(title?: string) {
        (
            await this.alertCtrl.create({
                header: this.translate.instant('PAGES.MEDIA.NEW_FOLDER'),
                inputs: [{ type: 'text', name: 'title', value: title || '', placeholder: this.translate.instant('COMMON.TITLE'), min: 1 }],
                buttons: [
                    'Avbryt',
                    {
                        text: 'Skapa',
                        handler: async ({ title }) => {
                            if (!title.replace(/ /g, '')?.length) {
                                this.toastService.error('PAGES.MEDIA.MUST_ENTER_TITLE');
                                this.showNewFolderPrompt();

                                return;
                            }

                            const loader = await this.loadingCtrl.create({
                                message: this.translate.instant('COMMON.LOADING'),
                                spinner: 'crescent',
                            });

                            loader.present();

                            this.api
                                .post('buckets', { title, parent_id: this.currentBucket.uuid })
                                .module(AwareHttpRequestModule.Media)
                                .execute()
                                .pipe(
                                    catchError((err) => {
                                        this.toastService.error('ERRORS.SOMETHING_WENT_WRONG');
                                        loader.dismiss();
                                        this.showNewFolderPrompt(title);

                                        throw throwError(() => err);
                                    }),
                                    tap(() => {
                                        loader.dismiss();
                                        this.toastService.success('PAGES.MEDIA.FOLDER_CREATED');

                                        this.getMedia(this.currentBucket, false).subscribe();
                                    }),
                                )
                                .subscribe();
                        },
                    },
                ],
            })
        ).present();
    }

    private async copyFileUrl(file: File) {
        await Clipboard.write({
            string: `${environment.api.url}/@media/${file.uuid}`,
        });

        this.toastService.info('PAGES.MEDIA.URL_COPIED');
    }

    private async showChangeFileOrBucketNamePrompt(source: File | Bucket, inputValue?: string) {
        (
            await this.alertCtrl.create({
                header: this.translate.instant('PAGES.MEDIA.RENAME'),
                inputs: [
                    { type: 'text', name: 'title', value: inputValue || source.title, placeholder: this.translate.instant('COMMON.TITLE') },
                ],
                buttons: [
                    this.translate.instant('COMMON.CANCEL'),
                    {
                        text: this.translate.instant('PAGES.MEDIA.RENAME'),
                        handler: async ({ title }) => {
                            if (title === source.title) return;
                            if (!title.replace(/ /g, '')?.length) {
                                this.toastService.error('PAGES.MEDIA.TITLE_CANT_BE_EMPTY');
                                this.showChangeFileOrBucketNamePrompt(source);

                                return;
                            }

                            const loader = await this.loadingCtrl.create({
                                message: this.translate.instant('COMMON.LOADING'),
                                spinner: 'crescent',
                            });

                            loader.present();

                            this.api
                                .put(`${source instanceof File ? 'files' : 'buckets'}/${source.uuid}`, { title })
                                .module(AwareHttpRequestModule.Media)
                                .execute()
                                .pipe(
                                    catchError((err) => {
                                        this.toastService.error('ERRORS.SOMETHING_WENT_WRONG');
                                        loader.dismiss();
                                        this.showChangeFileOrBucketNamePrompt(source, title);

                                        throw throwError(() => err);
                                    }),
                                    tap(() => {
                                        loader.dismiss();
                                        this.toastService.success(
                                            `${
                                                source instanceof File
                                                    ? this.translate.instant('PAGES.MEDIA.THE_FILE')
                                                    : this.translate.instant('PAGES.MEDIA.THE_FOLDER')
                                            } ${this.translate.instant('PAGES.MEDIA.HAS_BEEN_SAVED')}`,
                                        );

                                        source.title = title;
                                    }),
                                )
                                .subscribe();
                        },
                    },
                ],
            })
        ).present();
    }

    private async showDeleteFileOrBucketPrompt(source: File | Bucket) {
        (
            await this.alertCtrl.create({
                header: this.translate.instant('COMMON.CONFIRM'),
                message: `${this.translate.instant('PAGES.MEDIA.CONFIRM_REMOVE_THIS')} ${
                    source instanceof File
                        ? this.translate.instant('PAGES.MEDIA.THE_FILE').toLowerCase()
                        : this.translate.instant('PAGES.MEDIA.THE_FOLDER').toLowerCase()
                }? ${this.translate.instant('PAGES.MEDIA.THIS_CANT_BE_UNDONE')}`,
                buttons: [
                    {
                        text: 'Ta bort',
                        handler: async () => {
                            const loader = await this.loadingCtrl.create({
                                message: this.translate.instant('COMMON.LOADING'),
                                spinner: 'crescent',
                            });

                            loader.present();

                            this.api
                                .delete(`${source instanceof File ? 'files' : 'buckets'}/${source.uuid}`, null)
                                .module(AwareHttpRequestModule.Media)
                                .execute()
                                .pipe(
                                    catchError((err) => {
                                        this.toastService.error('ERRORS.SOMETHING_WENT_WRONG');
                                        loader.dismiss();

                                        throw throwError(() => err);
                                    }),
                                    tap(() => {
                                        loader.dismiss();
                                        this.toastService.success(
                                            `${
                                                source instanceof File
                                                    ? this.translate.instant('PAGES.MEDIA.THE_FILE')
                                                    : this.translate.instant('PAGES.MEDIA.THE_FOLDER')
                                            } ${this.translate.instant('PAGES.MEDIA.HAS_BEEN_REMOVED')}`,
                                        );

                                        this.getMedia(this.currentBucket, false).subscribe();
                                    }),
                                )
                                .subscribe();
                        },
                    },
                    'Avbryt',
                ],
            })
        ).present();
    }

    private cleanStack(bucket: Bucket) {
        if (this.stack.find((stackItem) => stackItem.uuid === bucket.uuid)) {
            this.stack.splice(this.stack.indexOf(bucket) + 1);
        }
    }

    private scrollStack() {
        setTimeout(() => {
            this.stackInner.nativeElement.scroll(9999, 0);
        }, 10);
    }

    private getMedia(bucket?: Bucket, showLoading = true) {
        let req = this.api.get('filesystem').module(AwareHttpRequestModule.Media).limit(1000).parameters({ mode: 'unit' });

        if (bucket?.uuid) {
            req = req.parameters({ bucket: bucket.uuid });
        }

        if (showLoading) {
            this.LOADING = true;
        }

        return req.execute().pipe(
            tap((result) => {
                if (bucket) {
                    bucket.children = result.data.buckets.map((bucket) => new Bucket(bucket));
                    bucket.files = result.data.files.map((file) => new File(file));
                } else {
                    const root = new Bucket({ children: result.data.buckets, files: result.data.files }, Bucket);

                    this.fileTree = root;
                    this.currentBucket = root;
                }

                if (showLoading) {
                    this.LOADING = false;
                }
            }),
            map(() => {
                return bucket;
            }),
        );
    }
}
