@if (RENDER) {
    <div class="relative w-12 h-12">
        @if (parsedClients.length === 1) {
            @if (parsedClients[0]?.image) {
                <img
                    class="w-12 h-12 object-cover flex-shrink-0"
                    [ngClass]="{
                        'rounded-full': shape === 'circle',
                        'rounded-none': shape === 'square',
                        'rounded-md': shape === 'rounded'
                    }"
                    [src]="parsedClients[0]?.image | imageByUuid: 150"
                />
            }
            @if (!parsedClients[0].image) {
                <div
                    class="w-12 h-12 bg-white flex justify-center items-center text-xl flex-shrink-0 border-2 border-solid border-[#f5f5f5]"
                    [ngClass]="{
                        'rounded-full': shape === 'circle',
                        'rounded-none': shape === 'square',
                        'rounded-md': shape === 'rounded'
                    }"
                >
                    {{ parsedClients[0]?.initials }}
                </div>
            }
        } @else {
            @if (parsedClients[0]?.image) {
                <img
                    class="w-8 h-8 object-cover flex-shrink-0 absolute top-0 right-0"
                    [ngClass]="{
                        'rounded-full': shape === 'circle',
                        'rounded-none': shape === 'square',
                        'rounded-md': shape === 'rounded'
                    }"
                    [src]="parsedClients[0]?.image | imageByUuid: 150"
                />
            }
            @if (!parsedClients[0]?.image) {
                <div
                    class="w-8 h-8 flex justify-center items-center text-base flex-shrink-0 absolute top-0 right-0 border-2 border-solid border-[#f5f5f5] bg-white"
                    [ngClass]="{
                        'rounded-full': shape === 'circle',
                        'rounded-none': shape === 'square',
                        'rounded-md': shape === 'rounded'
                    }"
                >
                    {{ parsedClients[0]?.initials }}
                </div>
            }
            @if (parsedClients[1]?.image) {
                <img
                    class="w-8 h-8 object-cover flex-shrink-0 absolute bottom-0 left-0 border-2 border-solid border-white bg-primary"
                    [ngClass]="{
                        'rounded-full': shape === 'circle',
                        'rounded-none': shape === 'square',
                        'rounded-md': shape === 'rounded'
                    }"
                    [src]="parsedClients[1]?.image | imageByUuid: 150"
                />
            }
            @if (!parsedClients[1]?.image) {
                <div
                    class="w-8 h-8 flex justify-center items-center text-sm text-primary-contrast flex-shrink-0 absolute bottom-0 left-0 border-2 border-solid border-[#f5f5f5] bg-primary bg-white"
                    [ngClass]="{
                        'rounded-full': shape === 'circle',
                        'rounded-none': shape === 'square',
                        'rounded-md': shape === 'rounded'
                    }"
                >
                    {{ parsedClients[1]?.initials }}
                </div>
            }
        }
    </div>
}
